import React from 'react';
import moment from 'moment';
import {
  InfoBoxContainer, InfoItem, SaluteCardInfoStyles, TitleActionBox,
} from './SaluteInfoCard.styles';
import { ModificaDatiButton } from '../common/Redesign.styles';
import editIcon from '../../../../assets/redesign_icons/edit-icon.svg';

function SaluteInfoCard({ customer, insuranceRequest, modifyData }) {
  return (
    <SaluteCardInfoStyles>
      <TitleActionBox>
        <h4>
          Informazioni
          {' '}
          <b>Salute</b>
        </h4>
        {modifyData && (
        <ModificaDatiButton onClick={() => modifyData()}>
          <p>Modifica Dati</p>
          <img src={editIcon} alt="Modifica Dati" />
        </ModificaDatiButton>
        )}
      </TitleActionBox>
      <InfoBoxContainer>
        <InfoItem>
          <h4>Contraente:</h4>
          <p>
            {customer.name}
            {' '}
            {customer.surname}
          </p>
        </InfoItem>
        <InfoItem>
          <h4>Tipo di Lavoro:</h4>
          <p>
            {insuranceRequest.work_type === 'attivita_manuali' ? 'Attivita Manuali' : 'Attivita non Manuali'}

          </p>
        </InfoItem>
        <InfoItem>
          <h4>Residenza:</h4>
          <p>
            {insuranceRequest.customer.province.name}
            ,
            {' '}
            {insuranceRequest.customer.province.car_plate_symbol}
          </p>
        </InfoItem>
        <InfoItem>
          <h4>Indirizzo:</h4>
          <p>
            {insuranceRequest.customer.address}
            ,
            {' '}
            {insuranceRequest.customer.house_number}
          </p>
        </InfoItem>
        <InfoItem>
          <h4>Inizio Copertura:</h4>
          <p>
            {moment(insuranceRequest.policy_effective_date).format('DD-MM-YYYY')}
          </p>
        </InfoItem>
      </InfoBoxContainer>
    </SaluteCardInfoStyles>
  );
}

export default SaluteInfoCard;
