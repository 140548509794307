import React, { useRef } from 'react';
import { ActionsWrapperResultPage } from '../redesignComponents/Purchase/SignatureType.styles';
import pdfIMG from '../../../assets/images/purchase/pdf.png';
import {
  generalAlertError,
  generalAlertSuccessToast,
} from '../../utils/alerts';
import { documentMethodManualUpload } from '../../../services/axios-client/axeCommons';

function ContractDocuments({
  insuranceRequest,
  downloadDocument,
  quote,
  collectQuoteData,
}) {
  const uploadPolizzaEmessa = useRef(null);
  const uploadMergedDocFileRef = useRef(null);

  const fireSignedDocUpload = (typeId) => {
    if ([13, 35, 36, 37, 38, 39, 40, 41].includes(typeId)) {
      uploadPolizzaEmessa.current.click();
    }
    if (typeId === 34) {
      uploadMergedDocFileRef.current.click();
    }
  };

  const uploadSignedDocument = async (documentId, files) => {
    const uploadedFile = files[0];
    const reader = new FileReader();

    reader.readAsDataURL(uploadedFile);
    if (uploadedFile.type !== 'application/pdf') {
      generalAlertError('Formato di file errato. Il file deve essere in .pdf');
      return;
    }
    reader.onload = () => {
      documentMethodManualUpload(quote.id, documentId, {
        document: reader.result.replace('data:application/pdf;base64,', ''),
      }).then(() => {
        collectQuoteData();
        generalAlertSuccessToast('File caricato');
      });
    };
  };

  return (
    <>
      <div className="col-12 col-md-12">
        <div className="card border mb-2 polizze-emessa-status-step-four">
          <div className="card-header bg-secondary text-white">
            <h2 className="h3 mb-0 card-title">Documenti Polizza</h2>
          </div>
          <div className="card-body">
            <div
              className="d-flex flex-wrap justify-content-start"
              style={{ gap: '20px' }}
            >
              {insuranceRequest.documents
                .filter(
                  (dr) => dr.document_type_id === 34 || dr.document_type_id === 35 || dr.document_type_id === 36
                    || dr.document_type_id === 37 || dr.document_type_id === 38
                    || dr.document_type_id === 39 || dr.document_type_id === 40
                    || dr.document_type_id === 41,
                )
                .map((document, index) => (
                  <div
                    className="list-group-item position-relative p-3 d-flex flex-column align-items-start"
                    key={index}
                    style={{
                      width: '320px',
                      height: '220px',
                      display: 'flex',
                      justifyContent: 'space-between',
                      textAlign: 'left',
                      position: 'relative',
                      borderWidth: '2px',
                      borderStyle: 'solid',
                      borderColor: '#00a651',
                      borderRadius: '8px',
                      padding: '15px',
                      alignItems: 'center',
                    }}
                  >
                    <span
                      className="position-absolute top-0 start-0 mt-2 ms-2"
                      style={{
                        backgroundColor: 'white',
                        color: 'black',
                        padding: '5px 10px',
                        borderRadius: '5px',
                        fontSize: '12px',
                        fontWeight: 'bold',
                      }}
                    >
                      {document.document_type.name}
                    </span>
                    <span
                      className="badge position-absolute top-0 end-0 mt-2 me-2"
                      style={{
                        backgroundColor:
                          document.is_signed_valid === 1
                            ? '#00a651'
                            : document.is_signed_valid === 0
                              ? '#dc3545'
                              : '#ffc107',
                      }}
                    >
                      {document.signable_document === 1
                        ? document.is_signed_valid === null
                          && document.signed_document_filename !== null
                          ? 'In Verifica'
                          : document.is_signed_valid === 0
                            ? 'Firma Non Valida'
                            : document.is_signed_valid === null
                            && document.signed_document_filename === null
                              ? 'Da Firmare'
                              : document.is_signed_valid === 1
                            && document.signed_document_filename !== null
                                ? 'Firmato'
                                : null
                        : 'Non Richiede Firma'}
                    </span>

                    <div
                      className="d-flex align-items-center w-100"
                      style={{
                        height: '100%',
                        flexDirection: 'row',
                        flexGrow: 1,
                        justifyContent: 'center',
                      }}
                    >
                      <img src={pdfIMG} width="25" alt="PDF" className="me-2" />
                      <h5 className="h6 mb-0 text-center">
                        {document.original_filename}
                      </h5>
                    </div>

                    <div
                      className="d-flex justify-content-center align-items-center w-100"
                      style={{ gap: '10px' }}
                    >
                      <button
                        className="border-0 btn btn-success"
                        onClick={() => downloadDocument(document.id)}
                      >
                        Scarica
                      </button>

                      {document.signable_document === 1
                        && (document.is_signed_valid === 0
                          || (document.is_signed_valid === null
                            && document.signed_document_filename === null)) && (
                            <ActionsWrapperResultPage>
                              {[13, 35, 36, 37, 38, 39, 40, 41].includes(
                                document.document_type.id,
                              ) && (
                              <div
                                className="action-button btn btn-warning"
                                onClick={() => fireSignedDocUpload(document.document_type_id)}
                              >
                                Carica
                                <input
                                  ref={uploadPolizzaEmessa}
                                  type="file"
                                  className="d-none"
                                  onChange={(e) => uploadSignedDocument(
                                    document.id,
                                    e.target.files,
                                  )}
                                />
                              </div>
                              )}
                              {document.document_type.id === 34 && (
                              <div
                                className="action-button btn btn-warning"
                                onClick={
                                  document.signed_document_filename === null
                                    ? () => fireSignedDocUpload(
                                      document.document_type.id,
                                    )
                                    : () => {}
                                }
                              >
                                Carica
                                <input
                                  ref={uploadMergedDocFileRef}
                                  type="file"
                                  className="d-none"
                                  onChange={(e) => uploadSignedDocument(
                                    document.id,
                                    e.target.files,
                                  )}
                                />
                              </div>
                              )}
                            </ActionsWrapperResultPage>
                      )}
                    </div>
                  </div>
                ))}
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

export default ContractDocuments;
