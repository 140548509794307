import React, { useEffect, useState } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faArrowLeftLong } from '@fortawesome/free-solid-svg-icons';
import { useNavigate, useParams } from 'react-router-dom';
import { useSelector } from 'react-redux';
import {
  PurchaseStyles,
  UploadDocumentsContainer,
  UploadWrapper,
} from '../purchase/Purchase.styles';
import InfoBoxHouse from '../../common/components/redesignComponents/Purchase/common/InfoBoxHouse';
import {
  purchaseInsuranceRequest,
  resetHousePurchase,
  updateBrokerageHome,
} from '../../features/purchases/purchasesActions';
import PageLoading from '../../common/components/elements/PageLoading';
import { SaveBox } from '../purchase/common/SaveBox';
import {
  NumberCurrent,
  SkipSignedDocs,
} from '../../common/components/redesignComponents/Purchase/SignatureType.styles';
import NonSignableDocumentComponent from '../purchase/common/NonSignableDocumentComponent';
import {
  apiDownloadDocument,
  deleteDocument,
} from '../../services/axios-client/axeCommons';
import {
  confirmAlert,
  generalAlertError,
  generalAlertSuccess,
} from '../../common/utils/alerts';
import WaitingForPaymentStatus from '../../common/components/redesignComponents/Purchase/WaitingForPaymentStatus';
import CompletedScreenHome from '../../common/components/elements/PurchaseAuto/CompletedScreenHome';
import axe from '../../services/axios-client/axiosClient';
import BrokeraggioComponent from '../../common/components/redesignComponents/Purchase/BrokeraggioComponent';

function PurchaseHomeRequest() {
  const navigate = useNavigate();
  const { requestToken } = useParams();

  const state = useSelector((store) => store.purchases.house);
  const {
    loading,
    quote,
    insuranceRequest,
    quoteStatus,
    // signMethod,
    backofficeUsers,
    rc_quotation,
    speseLegaliFamigliaQuote,
    incendioContenutoQuote,
    danniFamigliaConduzioneQuote,
    danniPropietarioQuote,
    incendioFabbricatoQuote,
    nonSignableDocs,
    risk_quotations,
  } = state;

  const navigateBack = () => {
    navigate(`/quotes/casa/${insuranceRequest.request_token}`);
  };

  const [supportMessage, setSupportMessage] = useState({
    subject: '',
    request_id: '',
    body: '',
    recipient: '',
  });

  const collectQuoteData = async () => {
    purchaseInsuranceRequest(requestToken, 'casa', setSupportMessage);
  };

  const checkIfAllNonSignableDocsAreUploaded = () => {
    let allUploaded = true;
    const allDocs = [
      ...insuranceRequest.documents.filter((d) => d.signable_document === 0),
      ...rc_quotation.documents.filter((d) => d.signable_document === 0),
    ];

    const nonSignaleDocTypeIds = nonSignableDocs.map((d) => d.id);

    for (let i = 0; i < allDocs.length; i++) {
      const document = allDocs[i];

      if (!nonSignaleDocTypeIds.includes(document.document_type_id)) {
        allUploaded = false;
      }
    }

    if (
      allDocs.length === 0
      || nonSignaleDocTypeIds.length !== allDocs.length
    ) {
      return false;
    }

    return allUploaded;
  };

  useEffect(() => {
    if (insuranceRequest && insuranceRequest.redirect_user === true) {
      navigate('/');
      generalAlertSuccess('La pratica è completa, attendi nostro feedback!');
    }
  }, [insuranceRequest]);

  const downloadDocument = async (documentId) => {
    const response = await apiDownloadDocument(documentId);

    const alink = document.createElement('a');
    alink.target = '_blank';
    alink.href = response.data.data.contents;
    alink.download = response.data.data.filename;
    alink.click();
  };

  const agreedDocuments = async () => {
    try {
      const response = await axe.post(`/agree-to-upload-documents/${insuranceRequest.id}`, {
        agree_to_upload_document: 1,
      });
      if (response.status !== 200) {
        generalAlertError('Non Accetato');
        return;
      }

      collectQuoteData();
    } catch (error) {
      //
    }
  };

  const removeDocument = async (documentId) => {
    try {
      const response = await deleteDocument(documentId);

      if (response.status === 200) {
        collectQuoteData();
      }
    } catch (err) {
      // console.error(err);
    }
  };

  const sendBrokerageAmount = async (amount, skipCollect = false) => {
    if (skipCollect) {
      await updateBrokerageHome(
        rc_quotation.id,
        amount,
        () => { },
        skipCollect,
      );
    } else {
      await updateBrokerageHome(
        rc_quotation.id,
        amount,
        collectQuoteData,
        skipCollect,
      );
    }
  };

  const getTotal = () => {
    let quoteInProccessAmount = 0;
    let risksTotalAmount = 0;

    if (rc_quotation !== null) {
      quoteInProccessAmount = parseFloat(rc_quotation.amount);
    }

    if (risk_quotations && risk_quotations.length > 0) {
      for (let i = 0; i < risk_quotations.length; i++) {
        const item = risk_quotations[i];

        risksTotalAmount += parseFloat(item.amount);
      }
    }

    const total = quoteInProccessAmount + risksTotalAmount;

    return total.toFixed(2);
  };

  useEffect(() => {
    collectQuoteData();

    return () => {
      resetHousePurchase();
    };
  }, []);

  useEffect(() => {
    if (loading === false && rc_quotation.is_expired === true) {
      navigate('/quote/casa', {
        state: { requestToken: insuranceRequest.request_token },
      });
      generalAlertError(
        'È necessario compilare nuovamente il preventivo!',
        'Link e scaduto!',
      );
    }
  }, [loading, quote]);

  if (loading) {
    return <PageLoading />;
  }

  return (
    <PurchaseStyles>
      {quoteStatus !== 7 && (
        <>
          <div className="container mt-0 d-flex gap-2 align-items-center justify-content-start mb-3">
            <div className="px-2 py-1 d-flex align-items-center gap-2">
              <div
                className="cursor-pointer d-flex align-items-center justify-content-start gap-2 fw-bold text-success"
                onClick={() => navigateBack()}
              >
                <div id="back-button">
                  <FontAwesomeIcon icon={faArrowLeftLong} />
                </div>
                <p className="ml-2 mb-0  cursor-pointer">Modifica Preventivo</p>
              </div>
            </div>
          </div>
          <div className="container mt-0">
            <InfoBoxHouse
              rc_quotation={rc_quotation}
              insuranceRequest={insuranceRequest}
              quoteStatus={quoteStatus}
              messagesModalData={{
                backofficeUsers,
                setSupportMessage,
                supportMessage,
                collectData: collectQuoteData,
              }}
              risk_quotations={risk_quotations}
            />
            {quoteStatus === 1 || quoteStatus === 2 || quoteStatus === 5 ? (
              <>

                <div className="documents-status-step-four">
                  <SaveBox
                    insuranceRequest={insuranceRequest}
                    quotationId={rc_quotation.id}
                    refresh={collectQuoteData}
                  />

                  <div className="row mt-3 ">
                    <div className="col-12 col-xxl-12 mt-2 mt-xxl-0">
                      <SkipSignedDocs className="skip-doc">
                        <p className="text-center mb-1">Accetta obblighi di inviare documenti</p>
                        <div className="d-flex justify-content-center align-items-center gap-3">
                          <label className="m-0">
                            <input type="checkbox" className="me-1" checked={insuranceRequest.agree_upload_documents === 1} disabled={insuranceRequest.agree_upload_documents === 1} onClick={() => confirmAlert('', () => agreedDocuments())} />
                            Dichiaro di aver adempiuto agli obblighi in ambito di invio documentazione precontrattuale

                          </label>
                        </div>
                      </SkipSignedDocs>
                    </div>
                  </div>

                  {insuranceRequest.agree_upload_documents !== null
                   && (
                   <div className="row mt-3">
                     <div className="col-12">
                       <UploadDocumentsContainer
                         className={
                         checkIfAllNonSignableDocsAreUploaded() === false
                           ? 'document-all-uploaded-border'
                           : ''
                       }
                       >
                         <div className="d-flex align-items-center justify-content-start">
                           <NumberCurrent>1</NumberCurrent>
                           <h2>
                             Carica i
                             {' '}
                             <b>documenti</b>
                             {' '}
                             del cliente
                           </h2>
                         </div>
                         <UploadWrapper>
                           {nonSignableDocs.map((docType, index) => (
                             <NonSignableDocumentComponent
                               key={index}
                               insuranceRequest={insuranceRequest}
                               documentType={docType}
                               downloadDocument={downloadDocument}
                               removeDocument={removeDocument}
                               rc_quotation={rc_quotation}
                               collectQuoteData={collectQuoteData}
                             />
                           ))}
                         </UploadWrapper>
                       </UploadDocumentsContainer>
                     </div>
                   </div>
                   )}

                </div>
                {/* <div className="documents-status-step-five">
                  {rc_quotation.sign_later === null && (
                    <div className="row mt-3">
                      <div className="col-12 col-xxl-12 mt-2 mt-xxl-0">
                        <SkipSignedDocs className="skip-doc">
                          <p className="text-center">Vuoi Firmare Dopo?</p>
                          <div className="d-flex justify-content-center align-items-center gap-3">
                            <button
                              className="btn btn-success border py-1 px-4"
                              onClick={() => continueWithoutSignDocs()}
                            >
                              Si
                            </button>
                            <button
                              className="btn btn-danger border py-1 px-4"
                              onClick={() => continueWithSignDocs()}
                            >
                              No
                            </button>
                          </div>
                        </SkipSignedDocs>
                      </div>
                    </div>
                  )}
                  {rc_quotation.sign_later === 1 && (
                    <div className="alert alert-success mt-3 border rounded-4">
                      Hai scelto di firmare i documenti più tardi.
                    </div>
                  )}
                  {rc_quotation.sign_later === 0 && (
                    <div className="row mt-3">
                      <div className="col-12 col-xxl-12 mt-2 mt-xxl-0">
                        <SignatureTypeHouse
                          quote={rc_quotation}
                          insuranceRequest={insuranceRequest}
                          collectQuoteData={collectQuoteData}
                          signableDocs={signableDocs}
                          requestedOptionalDocumentsSignable={
                            requestedOptionalDocumentsSignable
                          }
                        />
                      </div>
                    </div>
                  )}
                </div> */}

              </>
            ) : (
              ''
            )}

            {quoteStatus === 1 && (
            <BrokeraggioComponent
              rc_quotation={rc_quotation}
              sendBrokerageAmount={sendBrokerageAmount}
              getTotal={getTotal}
            />
            )}

            {quoteStatus === 4 && (
              <WaitingForPaymentStatus
                quote={quote}
                rc_quotation={rc_quotation}
                spese_legali_famiglia_quote={speseLegaliFamigliaQuote}
                incendio_contenuto_quote={incendioContenutoQuote}
                danni_famiglia_conduzione_quote={danniFamigliaConduzioneQuote}
                danni_propietario_quote={danniPropietarioQuote}
                incendio_fabbricato_quote={incendioFabbricatoQuote}
                insuranceRequest={insuranceRequest}
                collectQuoteData={collectQuoteData}
                assistenza_quotation={null}
                rivalsa_quotation={null}
                infortuni_conducente_quotation={null}
                cristalli_quotation={null}
                risk_quotations={risk_quotations}
              />
            )}

            {quoteStatus === 6 && (
              <div className="card-body mt-3">
                <div className="row">
                  <div className="col-6 offset-3">
                    <div className="alert alert-success" role="alert">
                      <h4 className="alert-heading">In Attesa Emissione</h4>
                      <p>
                        Quando l'agente di backoffice carica la polizza,
                        riceverai una notifica e il contratto potrà essere
                        scaricato.
                      </p>
                    </div>
                  </div>
                </div>
              </div>
            )}
          </div>
        </>
      )}
      {quoteStatus === 7 && (
        <>
          <div className="mt-0 gap-2 align-items-center justify-content-start mb-3">
            {/* <div>
                {localStorage.getItem('purchaseTour') === 'done' && (
                  <div style={{ cursor: 'pointer' }} onClick={() => firePurchaseTour()}>
                    <button className="btn btn-success px-2 py-1">Tutorial</button>
                  </div>
                )}
              </div> */}
          </div>

          <CompletedScreenHome
            risk_quotations={risk_quotations}
            quoteStatus={quoteStatus}
            customer={insuranceRequest.customer}
            rc_quotation={rc_quotation}
          />
        </>
      )}
    </PurchaseStyles>
  );
}

export default PurchaseHomeRequest;
