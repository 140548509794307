import React from 'react';
import moment from 'moment';
import MessagesModal from '../../../elements/MessagesModal';
import { BoxWrapper, ClientBox } from './CommonPurchase.styles';
import { quotationTypes } from '../../../../constants';
import { downloadFile } from '../../../../../services/axios-client/axeHelpers';
import axe from '../../../../../services/axios-client/axiosClient';

function InfoBoxSanitareStranieri({
  insuranceRequest,
  messagesModalData,
  statuses,
  rc_quotation,
  risk_quotations,
}) {
//   const downloadResourceDocs = async (id) => {
//     const res = await axe.get(`/download-resource-document/${id}/0`, {
//       responseType: 'blob',
//     });

  //     downloadFile(res.data);
  //   };

  const downloadPreventive = async () => {
    const res = await axe.get(
      `/quotations/${rc_quotation.id}/preventivo/generate`,
      {
        responseType: 'blob',
      },
    );

    downloadFile(res.data);
  };

  //   const doc = insuranceRequest.documents.find((doc) => doc.document_type_id === 34);

  //   const downloadDocument = async (documentId) => {
  //     if (documentId === undefined) {
  //       return;
  //     }
  //     const response = await apiDownloadDocument(documentId);
  //     const alink = document.createElement('a');
  //     alink.target = '_blank';
  //     alink.href = response.data.data.contents;
  //     alink.download = response.data.data.filename;
  //     alink.click();
  //   };

  const getTotal = () => {
    let quoteInProccessAmount = 0;
    let risksTotalAmount = 0;

    if (rc_quotation !== null) {
      quoteInProccessAmount = parseFloat(rc_quotation.amount);
    }

    if (risk_quotations && risk_quotations.length > 0) {
      for (let i = 0; i < risk_quotations.length; i++) {
        const item = risk_quotations[i];

        risksTotalAmount += parseFloat(item.amount);
      }
    }

    const total = quoteInProccessAmount + risksTotalAmount + rc_quotation.brokerage_amount;

    return total.toFixed(2);
  };
  return (
    <>
      <BoxWrapper>
        <div className="row gap-2 gap-xl-0">
          <div className="col-12 col-xl-4">
            <ClientBox className="documents-status-step-one pagament-status-step-one">
              <h6 className="mt-2">Contraente:</h6>
              <h5>
                {insuranceRequest.customer.name}
                {' '}
                {insuranceRequest.customer.surname}
              </h5>

              <h6 className="mt-2">Residenza:</h6>
              <h5>
                {`${insuranceRequest.customer.address} ${insuranceRequest.customer.house_number}, ${insuranceRequest.customer.postal_code} ${insuranceRequest.customer.city_of_residence.name} `
                + `(${insuranceRequest.customer.city_of_residence.communal_territory.car_plate_symbol})`}
              </h5>
              <h6 className="mt-2">Decorrenza:</h6>
              <h5>
                {moment(insuranceRequest.policy_effective_date).format(
                  'DD-MM-YYYY',
                )}
              </h5>
              <h6 className="mt-2">Status:</h6>
              <h5>
                {statuses.find((stat) => stat.id === rc_quotation.status).name}
              </h5>
              <h6 className="mt-2">Straniero/e:</h6>
              <h5>
                {insuranceRequest.sanitaria_stranieri.name}
                {' '}
                {insuranceRequest.sanitaria_stranieri.surname}
              </h5>
              <h6 className="mt-2">Durata in giorni:</h6>
              <h5>
                {insuranceRequest.sanitaria_stranieri.days_of_staying}
              </h5>
              {/* {rc_quotation.company_id === 8 && (
              <h6
                className="mt-2 text-decoration-underline"
                onClick={() => downloadResourceDocs(16)}
                style={{ cursor: 'pointer' }}
              >
                Informazioni Precontrattuali Europe Assistance
              </h6>
              )} */}
              <h6
                className="mt-2 text-decoration-underline"
                onClick={() => downloadPreventive()}
                style={{ cursor: 'pointer' }}
              >
                Preventivo
              </h6>

            </ClientBox>
          </div>
          <div className="col-12 col-xl-8">
            <ClientBox>
              <h4>Informazioni Prodotti</h4>
              <hr />
              <div className="table table-responsive">
                <table className="table table-striped">
                  <thead>
                    <tr>
                      <th scope="col">Prodotto</th>
                      <th scope="col">Compagnia</th>
                      <th scope="col">Frequenza Pagamento</th>
                      <th scope="col">Premio</th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr>
                      <td>{rc_quotation.product.name}</td>
                      <td>
                        {rc_quotation.company_id !== null
                          ? rc_quotation.company.name
                          : rc_quotation.preventivas_company.company_name}
                      </td>
                      <td>
                        {rc_quotation.payment_frequency === 1
                          ? 'Annuale'
                          : 'Semestrale'}
                      </td>
                      <td>
                        {parseFloat(rc_quotation.amount)}
                        {' '}
                        &euro;
                      </td>
                    </tr>

                    {risk_quotations
                  && risk_quotations.map((quote, index) => (
                    <tr key={index}>
                      <td>
                        {
                          quotationTypes.find(
                            (type) => type.id === quote.quote_type,
                          ).name
                        }
                      </td>
                      <td>{quote.company.name}</td>
                      <td>
                        {quote.payment_frequency === 1
                          ? 'Annuale'
                          : 'Semestrale'}
                      </td>
                      <td>
                        {parseFloat(quote.amount).toFixed(2)}
                        {' '}
                        &euro;
                      </td>
                    </tr>
                  ))}
                    <tr>
                      <td>Brokeraggio</td>
                      <td />
                      <td>
                        {rc_quotation.payment_frequency === 1
                          ? 'Annuale'
                          : 'Semestrale'}
                      </td>
                      <td>
                        {parseFloat(rc_quotation.brokerage_amount).toFixed(2)}
                        {' '}
                        &euro;
                      </td>
                    </tr>
                  </tbody>
                  <tfoot>
                    <tr>
                      <td className="fw-bold">Totale</td>
                      <td />
                      <td />
                      <td className="fw-bold">
                        &euro;
                        {getTotal()}
                      </td>
                    </tr>
                  </tfoot>
                </table>
              </div>
            </ClientBox>
          </div>
        </div>
      </BoxWrapper>
      <MessagesModal
        {...messagesModalData}
        threads={insuranceRequest.threads}
      />
    </>
  );
}

export default InfoBoxSanitareStranieri;
