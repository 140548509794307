import React, { useEffect, useRef, useState } from 'react';
import { yupResolver } from '@hookform/resolvers/yup';
import * as yup from 'yup';
import { useForm, Controller } from 'react-hook-form';
import { useNavigate } from 'react-router-dom';
import moment from 'moment';
import { Range } from 'react-range';
import {
  AddGuaranteesButton,
  BankTransferInfo,
  BankTransferInfoItem,
  DefaultInfo,
  ErrorMessage,
  FormInputGroup,
  FormInputGroupBrokeragio,
  FormInputGroupContainer,
  InfoBoxContainer,
  InfoItem,
  MarkupContainer,
  MarkupPrice,
  MarkupSlider,
  MarkupSliderContainer,
  MarkupTitle,
  PayByBankTransfer,
  PayByCardContainer,
  PayNowButton,
  PaymentProofStyled,
  QuoteAmountTable,
  QuoteInfoContainer,
  // RemoveGuaranteeButton,
  WaitingForPaymentInfo,
  WaitingForPaymentQuoteInfo,
  WarningInfo,
} from './WaitingForPaymentStatus.styles';
// import { guideType } from '../../../constants';
// import { BACKEND_BASE_URL } from '../../../../config';
import UploaderBonifico from '../Uploader/UploaderBonifico';
import warningIcon from '../../../../assets/redesign_icons/warning-icon.svg';
import parseJson from '../../../helpers/parseJSON';
import axe from '../../../../services/axios-client/axiosClient';
import PageLoading from '../../elements/PageLoading';
import {
  confirmAlert,
  generalAlertError,
  generalAlertSuccess,
} from '../../../utils/alerts';
import {
  updateBrokerage,
  updateBrokerageProfessionale,
  updateBrokerageTravel,
  updateService,
} from '../../../../features/purchases/purchasesActions';
import { primaQuoteTypes, quotationTypes } from '../../../constants';

const schema = yup.object({
  card_number: yup
    .string()
    .required('Il campo è obbligatorio')
    .matches(/^[0-9]+$/, 'Dovrebbe contenere solo numeri')
    .min(16, 'Il campo dovrebbe contenere 16 numeri'),
  card_expiry: yup
    .string()
    .required('Il campo è obbligatorio')
    .matches(/^(0[1-9]|1[0-2])\/?([0-9]{2})$/, 'Il formato non è valido')
    .min(5, 'Deve contenere una scadenza valida nel formato specificato')
    .max(5, 'Deve contenere una scadenza valida nel formato specificato'),
  card_cvv: yup
    .string()
    .required('Il campo è obbligatorio')
    .matches(/^[0-9]+$/, 'Dovrebbe contenere solo numeri')
    .min(3, 'Dovrebbe contenere almeno 3 numeri')
    .max(4, 'Dovrebbe contenere meno di 5 numeri'),
});

// const translateError = (error) => {
//   const translations = {
//     'Invalid expiry month': 'Mese di scadenza non valido',
//     'Invalid expiry year': 'Anno di scadenza non valido',
//     'Credit card with wrong check-digit': 'Carta di credito con cvv errata',
//     'Not numeric characters in credit card number':
//       'Caratteri non numerici nel numero della carta di credito',
//   };

//   if (translations[error] !== undefined) {
//     return translations[error];
//   }

//   return error;
// };
const STEP = 1;
const MIN = 0;

function WaitingForPaymentStatus({
  insuranceRequest,
  collectQuoteData,
  rc_quotation,
  hideBrokerageAndService = false,
  risk_quotations,
}) {
  const navigate = useNavigate();
  const [quoteData, setQuoteData] = useState(null);
  const [loading, setLoading] = useState(true);
  const [paymentAuthorizationUrl, setPaymentAuthorizationUrl] = useState(null);
  const [val, setVal] = useState({ values: [0] });
  const valRef = useRef(null);
  const [service, setService] = useState({ values: [0] });

  const initialService = { values: [60] };

  const serviceRef = useRef(null);
  valRef.current = val;
  serviceRef.current = service;

  // const [brokerageAmount, setBrokerageAmount] = useState(0);

  const user = parseJson(localStorage.getItem('user'));

  const {
    register,
    handleSubmit,
    control,
    setValue,
    reset: resetCardForm,
    formState: { errors },
  } = useForm({
    mode: 'all',
    resolver: yupResolver(schema),
    shouldFocusError: true,
  });

  const sendBrokerageAmount = async (skipCollect = false) => {
    if (rc_quotation.brokerage_amount > 0) {
      generalAlertError('Non puoi piu cambiare il brokeraggio in questo momento!');
      return;
    }

    if (
      rc_quotation.product_id === 1
      || rc_quotation.product_id === 2
      || rc_quotation.product_id === 3
      || rc_quotation.product_id === 9
      || rc_quotation.product_id === 10
      || rc_quotation.product_id === 11
      || rc_quotation.product_id === 12
      || rc_quotation.product_id === 13
      || rc_quotation.product_id === 14
    ) {
      if (skipCollect) {
        await updateBrokerage(
          rc_quotation.id,
          valRef.current.values[0],
          () => { },
          skipCollect,
        );
      } else {
        await updateBrokerage(
          rc_quotation.id,
          valRef.current.values[0],
          collectQuoteData,
          skipCollect,
        );
      }
    }

    if (rc_quotation.product_id === 4) {
      if (skipCollect) {
        await updateBrokerageProfessionale(
          rc_quotation.id,
          valRef.current.values[0],
          () => { },
          skipCollect,
        );
      } else {
        await updateBrokerageProfessionale(
          rc_quotation.id,
          valRef.current.values[0],
          collectQuoteData,
          skipCollect,
        );
      }
    }

    if (rc_quotation.product_id === 8) {
      if (skipCollect) {
        await updateBrokerageTravel(
          rc_quotation.id,
          valRef.current.values[0],
          () => { },
          skipCollect,
        );
      } else {
        await updateBrokerageTravel(
          rc_quotation.id,
          valRef.current.values[0],
          collectQuoteData,
          skipCollect,
        );
      }
    }
  };

  const sendServiceAmount = async (skipCollect = false) => {
    if (serviceRef.current.values[0] < 60) {
      generalAlertError('Valore Servizio Non Puo Essere meno di 60 Euro');
      return;
    }
    if (
      rc_quotation.product_id === 1
      || rc_quotation.product_id === 2
      || rc_quotation.product_id === 3
    ) {
      if (skipCollect) {
        await updateService(
          rc_quotation.id,
          serviceRef.current.values[0],
          () => { },
          skipCollect,
        );
      } else {
        await updateService(
          rc_quotation.id,
          serviceRef.current.values[0],
          collectQuoteData,
          skipCollect,
        );
      }
    }
  };

  // const setServiceAmountToZero = async (skipCollect = false) => {
  //   if (
  //     rc_quotation.product_id === 1
  //     || rc_quotation.product_id === 2
  //     || rc_quotation.product_id === 3
  //   ) {
  //     if (skipCollect) {
  //       await updateService(rc_quotation.id, 0, () => {}, skipCollect);
  //     } else {
  //       await updateService(rc_quotation.id, 0, collectQuoteData, skipCollect);
  //     }
  //   }
  // };

  const checkBrokerageVal = (skipCollect = true) => new Promise((resolve) => {
    if (rc_quotation.brokerage_amount != valRef.current.values[0]) {
      confirmAlert(
        'Il brokeraggio non è stato aggiornato, vuoi aggiornarlo?',
        async () => {
          await sendBrokerageAmount(skipCollect);
          resolve(true);
        },
        () => resolve(false),
      );
    } else {
      resolve(true);
    }
  });

  const checkServiceVal = (skipCollect = true) => new Promise((resolve) => {
    if (
      (rc_quotation.product_id === 1
        || rc_quotation.product_id === 2
        || rc_quotation.product_id === 3)
      && rc_quotation.quotation_services.length === 0
      && initialService.values[0] <= serviceRef.current.values[0]
    ) {
      confirmAlert(
        'Il servizio non è stato aggiornato, vuoi aggiornarlo?',
        async () => {
          await sendServiceAmount(skipCollect);
          resolve(true);
        },
        () => resolve(false),
      );
    } else if (
      (rc_quotation.product_id === 1
        || rc_quotation.product_id === 2
        || rc_quotation.product_id === 3)
      && rc_quotation.quotation_services.length > 0
      && rc_quotation.quotation_services[0].amount_gross
      != serviceRef.current.values[0]
    ) {
      confirmAlert(
        'Il servizio non è stato aggiornato, vuoi aggiornarlo?',
        async () => {
          await sendServiceAmount(skipCollect);
          resolve(true);
        },
        () => resolve(false),
      );
    } else {
      resolve(true);
    }
  });

  const uploadPaymentProf = async (file) => {
    // check if brokerage amount is updated
    if (!(await checkBrokerageVal())) {
      return;
    }

    // check if service amount is updated
    if (!(await checkServiceVal())) {
      return;
    }

    const uploadedFile = file;
    const reader = new FileReader();
    reader.readAsDataURL(uploadedFile);
    reader.onload = () => {
      const fileType = uploadedFile.type;

      axe
        .post(
          `/insurance-request/${insuranceRequest.id}/upload-payment-proof`,
          {
            document: reader.result.replace(`data:${fileType};base64,`, ''),
            file_type: fileType,
          },
        )
        .then(() => {
          navigate('/');
          generalAlertSuccess(
            'Stiamo verificando la prova del pagamento, attendi nostro feedback!',
          );
        });
    };
  };

  const collectQuoteInformation = async () => {
    setLoading(true);

    try {
      const response = await axe.get(
        `/quotations/${rc_quotation.id}/for-payment`,
      );
      if (response.status === 200) {
        setQuoteData(response.data.data);
      }

      setLoading(false);
    } catch (err) {
      setLoading(false);
    }
  };

  const submitData = async (data) => {
    // check if brokerage amount is updated
    if (!(await checkBrokerageVal())) {
      return;
    }

    // check if service amount is updated
    if (!(await checkServiceVal())) {
      return;
    }

    setLoading(true);

    try {
      const response = await axe.post(
        `/payments/insurance-request/${insuranceRequest.id}/pay`,
        {
          card: {
            number: data.card_number,
            exp_month: data.card_expiry.split('/')[0],
            exp_year: data.card_expiry.split('/')[1],
            cvv: data.card_cvv,
          },
        },
      );

      if (response.status === 400) {
        setLoading(false);
      } else if (response.status === 500) {
        setLoading(false);
      } else if (response.status === 200) {
        setPaymentAuthorizationUrl(response.data.data.user_redirect);
        resetCardForm();
        collectQuoteInformation();
      }
    } catch (err) {
      setLoading(false);
    }
  };

  const formatInput = (value) => value.replace(/(\d{2})(\d{0,3})/, '$1/$2');

  const handleInputChange = (e) => {
    const inputValue = e.target.value.replace(/\//g, '');
    const formattedValue = formatInput(inputValue);

    e.target.value = formattedValue;

    setValue('card_expiry', formattedValue);
  };

  useEffect(() => {
    collectQuoteInformation();
  }, [rc_quotation.id]);

  const payWithCompensation = () => {
    if (rc_quotation.with_compensation !== 1) {
      // check if service amount is updated
      // axe.get(`/quotations/${quote.id}/pay-with-compensation/${quote.with_compensation === 1 ? 0 : 1}`).then((res) => {
      axe
        .get(
          `/insurance-request/${insuranceRequest.id}/pay-with-compensation/${rc_quotation.with_compensation === 1 ? 0 : 1
          }`,
        )
        .then((res) => {
          if (res.status === 200) {
            collectQuoteData();
            generalAlertSuccess('Pratica pagata con compensazione mensile!');
            navigate('/');
            return;
          }
          generalAlertError(res?.data?.message);
        });
      return;
    }

    if (
      rc_quotation.is_compensation_confirmed === 1
      && rc_quotation.amount_paid !== null
      && rc_quotation.amount !== rc_quotation.amount_paid
    ) {
      axe
        .get(
          `/insurance-request/${insuranceRequest.id}/pay-with-compensation/1`,
        )
        .then((res) => {
          if (res.status === 200) {
            collectQuoteData();
            generalAlertSuccess('Pratica pagata con compensazione mensile!');
            navigate('/');
            return;
          }
          generalAlertError(res?.data?.message);
        });
    }
  };

  // const payWithCompensationTwoPayments = () => {
  //   confirmAlert('Dopo sei mesi devi pagare la altra rate', () => payWithCompensation());
  // };
  const compensationConfirmAlertHandle = async () => {
    // check if brokerage amount is updated
    if (!(await checkBrokerageVal())) {
      return;
    }

    // check if service amount is updated
    if (!(await checkServiceVal())) {
      return;
    }

    confirmAlert('Sei sicuro di pagare con compensazione?', () => payWithCompensation());
    // if (quote.payment_frequency === 2) {
    //   confirmAlert('Sei sicuro di pagare con compensazione?', () => payWithCompensationTwoPayments());
    // } else {
    //   confirmAlert('Sei sicuro di pagare con compensazione?', () => payWithCompensation());
    // }
  };

  useEffect(() => {
    if (rc_quotation !== null) {
      setVal({
        values: [rc_quotation.brokerage_amount],
      });
      if (
        rc_quotation.quotation_services
        && rc_quotation.quotation_services.length > 0
      ) {
        setService({
          values: [rc_quotation.quotation_services[0].amount_gross],
        });
      }
    }
  }, [rc_quotation]);

  if (loading) {
    return <PageLoading />;
  }

  const getTotalAmount = () => {
    let rcTotalAmount = 0;
    let risksTotalAmount = 0;
    let rcBrokeraggio = 0;
    let rcServiceAmount = 0;
    let primaGuaranteesPrices = 0;

    if (rc_quotation.company_id === 2) {
      rc_quotation.guarantees.forEach((guarantee) => {
        primaGuaranteesPrices += parseFloat(guarantee.amount) || 0;
      });
    }

    rcTotalAmount = rc_quotation.amount;

    rcBrokeraggio = rc_quotation.amount_paid === null ? rc_quotation.brokerage_amount : 0;
    rcServiceAmount = rc_quotation.quotation_services
      && rc_quotation.quotation_services.length > 0
      ? rc_quotation.quotation_services[0].amount_gross
      : 0;

    if (risk_quotations && risk_quotations.length > 0) {
      for (let i = 0; i < risk_quotations.length; i++) {
        const item = risk_quotations[i];

        risksTotalAmount += parseFloat(item.amount);
      }
    }

    return parseFloat(
      rcTotalAmount
      + risksTotalAmount
      + rcBrokeraggio
      + rcServiceAmount
      + primaGuaranteesPrices,
    ).toFixed(2);
  };

  const paymentsMadeWithCard = quoteData.quotation_payments.filter(
    (p) => p.status === 4,
  ).length;
  const paymentsMadeWithBonifico = rc_quotation.payment_proofs.filter(
    (p) => p.payment_proof_valid === 1,
  ).length;
  const paymentsMadeWithCompensation = rc_quotation.product_id === 8
    ? 0
    : rc_quotation.payments.filter((p) => p.payment_type === 'compensation')
      .length;
  const paymentMadeCount = paymentsMadeWithBonifico
    + paymentsMadeWithCard
    + paymentsMadeWithCompensation;

  const totalPaymentsRequired = rc_quotation.payment_frequency ?? 1;

  const get_amount_to_pay = () => {
    let primaGuaranteesPrices = 0;
    const brokerage = rc_quotation.amount_paid === null ? rc_quotation.brokerage_amount : 0;

    if (rc_quotation.company_id === 2) {
      rc_quotation.guarantees.forEach((guarantee) => {
        primaGuaranteesPrices += parseFloat(guarantee.amount) || 0;
      });
    }

    let amount_to_pay = parseFloat(
      quoteData.quotation.amount / quoteData.quotation.payment_frequency
      + brokerage
      + (rc_quotation.quotation_services
        && rc_quotation.quotation_services.length > 0
        ? rc_quotation.quotation_services[0].amount_gross
        : 0),
    );

    if (risk_quotations && risk_quotations.length > 0) {
      for (let i = 0; i < risk_quotations.length; i++) {
        const item = risk_quotations[i];

        amount_to_pay
          += item.company_id == rc_quotation.company_id
            ? parseFloat(item.amount / rc_quotation.payment_frequency)
            : parseFloat(item.amount / item.payment_frequency);
      }
    }

    return (
      amount_to_pay
      + primaGuaranteesPrices / rc_quotation.payment_frequency
    ).toFixed(2);
  };

  const getAmountForBrokerage = () => {
    let primaGuaranteesPrices = 0;

    if (rc_quotation.company_id === 2) {
      rc_quotation.guarantees.forEach((guarantee) => {
        primaGuaranteesPrices += parseFloat(guarantee.amount) || 0;
      });
    }

    let amount_to_pay = parseFloat(
      quoteData.quotation.amount / quoteData.quotation.payment_frequency
      + (rc_quotation.quotation_services
        && rc_quotation.quotation_services.length > 0
        ? rc_quotation.quotation_services[0].amount_gross
        : 0),
    );

    if (risk_quotations && risk_quotations.length > 0) {
      for (let i = 0; i < risk_quotations.length; i++) {
        const item = risk_quotations[i];

        amount_to_pay
          += item.company_id == rc_quotation.company_id
            ? parseFloat(item.amount / rc_quotation.payment_frequency)
            : parseFloat(item.amount / item.payment_frequency);
      }
    }

    return (
      amount_to_pay
      + primaGuaranteesPrices / rc_quotation.payment_frequency
    ).toFixed(2);
  };

  return (
    <>
      <div className="row">
        <div className="col-12 col-xxl-12 mt-2 mb-2 mt-xxl-0">
          {!user.managed_by_broker_id
            && rc_quotation.amount_paid === null
            && (
              <div className="pagament-status-step-four">
                <MarkupContainer>
                  <div className="row">
                    <div className="col-12 col-xxl-8">
                      <MarkupTitle>Aggiungi Brokeraggio</MarkupTitle>
                      <DefaultInfo>
                        <img src={warningIcon} alt="" />
                        <p>
                          Fai attenzione perché il brokeraggio non può
                          essere modificato dopo che è stato effettuato un
                          pagamento.
                        </p>
                      </DefaultInfo>
                    </div>
                    <div className="d-flex align-items-center justify-content-start col-xxl-4">
                      <AddGuaranteesButton
                        onClick={() => sendBrokerageAmount()}
                      >
                        Aggiungi
                      </AddGuaranteesButton>
                    </div>
                  </div>
                  <FormInputGroupBrokeragio>
                    <label htmlFor="">Inserisci Brokeraggio</label>
                    <input
                      type="number"
                      placeholder="Inserisci il numero della carta"
                      value={val.values}
                      max={
                        (getAmountForBrokerage()
                          * 30)
                        / 100
                      }
                      onChange={(e) => {
                        const maxVal = (getAmountForBrokerage()
                          * 30)
                          / 100;
                        if (e.target.value > maxVal) {
                          setVal({
                            values: [parseInt(maxVal)],
                          });
                        } else {
                          setVal({
                            values: [
                              Math.floor(e.target.value * 100) / 100,
                            ],
                          });
                        }
                      }}
                    />
                  </FormInputGroupBrokeragio>
                  <div className="row">
                    <div className="col-12">
                      <MarkupSliderContainer>
                        <MarkupPrice>&euro; 0.00</MarkupPrice>
                        <MarkupSlider>
                          <Range
                            values={val.values}
                            step={STEP}
                            min={MIN}
                            max={
                              (getAmountForBrokerage()
                                * 30)
                              / 100
                            }
                            onChange={(values) => {
                              const maxAllowed = (getAmountForBrokerage()
                                * 30)
                                / 100;
                              const amount = values[0] + 0.5;
                              if (maxAllowed < amount) {
                                setVal({
                                  values: [
                                    Math.floor(maxAllowed * 100) / 100,
                                  ],
                                });
                              } else {
                                setVal({
                                  values: [Math.floor(values * 100) / 100],
                                });
                              }
                            }}
                            renderTrack={({ props, children }) => (
                              <div
                                onMouseDown={props.onMouseDown}
                                onTouchStart={props.onTouchStart}
                                style={{
                                  ...props.style,
                                  height: '36px',
                                  display: 'flex',
                                  width: '95%',
                                  margin: '0 auto',
                                }}
                              >
                                <div
                                  className="range-responsive-slide"
                                  ref={props.ref}
                                  style={{
                                    height: '20px',
                                    width: '100%',
                                    borderRadius: '140px',
                                    alignSelf: 'center',
                                    backgroundColor: '#DFF3EA',
                                  }}
                                >
                                  {children}
                                </div>
                              </div>
                            )}
                            renderThumb={({ props }) => (
                              <div
                                className="range-responsive-circle"
                                {...props}
                                style={{
                                  ...props.style,
                                  height: '40px',
                                  width: '40px',
                                  borderRadius: '50%',
                                  backgroundColor: '#00A651',
                                  display: 'flex',
                                  justifyContent: 'center',
                                  alignItems: 'center',
                                  border: 'none',
                                  boxShadow: 'none',
                                  outline: 'none',
                                }}
                              >
                                <div
                                  className="range-responsive-text"
                                  style={{
                                    position: 'absolute',
                                    top: '-35px',
                                    width: '100px',
                                    color: '#00A551',
                                    textAlign: 'center',
                                    fontFamily: 'Poppins',
                                    fontSize: '20px',
                                    fontStyle: 'normal',
                                    fontWeight: '500',
                                    lineHeight: '24px',
                                  }}
                                >
                                  &euro;
                                  {' '}
                                  {Math.floor(val.values[0] * 100) / 100}
                                </div>
                              </div>
                            )}
                          />
                        </MarkupSlider>
                        <MarkupPrice>
                          &euro;
                          {' '}
                          {(
                            (getAmountForBrokerage()
                              * 30)
                            / 100
                          ).toFixed(2)}
                        </MarkupPrice>
                      </MarkupSliderContainer>
                    </div>
                  </div>
                </MarkupContainer>
                {/* {(rc_quotation.product_id === 1
                  || rc_quotation.product_id === 2
                  || rc_quotation.product_id === 3) && (
                  <MarkupContainer className="mt-2">
                    <div className="row">
                      <div className="col-12 col-xxl-8">
                        <MarkupTitle>Aggiungi Servizio</MarkupTitle>
                        <DefaultInfo>
                          <img src={warningIcon} alt="" />
                          <p>
                            Fai attenzione perché il servizio non può essere
                            modificato dopo che è stato effettuato un pagamento.
                          </p>
                        </DefaultInfo>
                      </div>
                      <div className="col-12 col-xxl-4">
                        <div className="d-flex align-items-center justify-content-end">
                          <AddGuaranteesButton
                            onClick={() => sendServiceAmount()}
                          >
                            Aggiungi
                          </AddGuaranteesButton>
                          {rc_quotation.quotation_services.length !== 0
                          && serviceRef.current.values[0] > 0 ? (
                            <RemoveGuaranteeButton
                              className="ms-2"
                              onClick={() => setServiceAmountToZero()}
                            >
                              Rimuovi
                            </RemoveGuaranteeButton>
                            ) : (
                              ''
                            )}
                        </div>
                      </div>
                    </div>
                    <div className="row">
                      <div className="col-12">
                        <MarkupSliderContainer>
                          <MarkupPrice>&euro; 60</MarkupPrice>
                          <MarkupSlider>
                            <Range
                              values={
                                service.values < 60
                                  ? initialService.values
                                  : service.values
                              }
                              step={STEP}
                              min={60}
                              max={90}
                              onChange={(values) => setService({ values })}
                              renderTrack={({ props, children }) => (
                                <div
                                  onMouseDown={props.onMouseDown}
                                  onTouchStart={props.onTouchStart}
                                  style={{
                                    ...props.style,
                                    height: '36px',
                                    display: 'flex',
                                    width: '95%',
                                    margin: '0 auto',
                                  }}
                                >
                                  <div
                                    className="range-responsive-slide"
                                    ref={props.ref}
                                    style={{
                                      height: '20px',
                                      width: '100%',
                                      borderRadius: '140px',
                                      alignSelf: 'center',
                                      backgroundColor: '#DFF3EA',
                                    }}
                                  >
                                    {children}
                                  </div>
                                </div>
                              )}
                              renderThumb={({ props }) => (
                                <div
                                  className="range-responsive-circle"
                                  {...props}
                                  style={{
                                    ...props.style,
                                    height: '40px',
                                    width: '40px',
                                    borderRadius: '50%',
                                    backgroundColor: '#00A651',
                                    display: 'flex',
                                    justifyContent: 'center',
                                    alignItems: 'center',
                                    border: 'none',
                                    boxShadow: 'none',
                                    outline: 'none',
                                  }}
                                >
                                  <div
                                    className="range-responsive-text"
                                    style={{
                                      position: 'absolute',
                                      top: '-35px',
                                      width: '100px',
                                      color: '#00A551',
                                      textAlign: 'center',
                                      fontFamily: 'Poppins',
                                      fontSize: '20px',
                                      fontStyle: 'normal',
                                      fontWeight: '500',
                                      lineHeight: '24px',
                                    }}
                                  >
                                    &euro;
                                    {' '}
                                    {service.values[0].toFixed(2)}
                                  </div>
                                </div>
                              )}
                            />
                          </MarkupSlider>
                          <MarkupPrice>&euro; 90</MarkupPrice>
                        </MarkupSliderContainer>
                      </div>
                    </div>
                  </MarkupContainer>
                )} */}
              </div>
            )}
        </div>
        <div className="col-12 col-xxl-6 pagament-status-step-five">
          <WaitingForPaymentInfo>
            <h3>
              Informazioni
              {' '}
              <b>cliente</b>
            </h3>
            <InfoBoxContainer>
              {quoteData.insurance_request.customer && (
                <>
                  <InfoItem>
                    <h4>
                      {quoteData.insurance_request.customer.gender !== 'G'
                        ? 'Nome e Cognome'
                        : 'Ragione Sociale'}
                      :
                    </h4>
                    <p>
                      {quoteData.insurance_request.customer.gender !== 'G'
                        ? `${quoteData.insurance_request.customer.name} ${quoteData.insurance_request.customer.surname}`
                        : quoteData.insurance_request.customer.business_name}
                    </p>
                  </InfoItem>
                  <InfoItem>
                    <h4>Telefono:</h4>
                    <p>{quoteData.insurance_request.customer.phone}</p>
                  </InfoItem>
                  <InfoItem>
                    <h4>Email:</h4>
                    <p>{quoteData.insurance_request.customer.email}</p>
                  </InfoItem>
                  <InfoItem>
                    <h4>Indirizzo:</h4>
                    <p>
                      {quoteData.insurance_request.customer.address}
                      {' '}
                      {quoteData.insurance_request.customer.house_number
                        !== null
                        && quoteData.insurance_request.customer.house_number}
                      ,
                      {' '}
                      {`${quoteData.insurance_request.customer.city_of_residence.name} ${insuranceRequest.customer.postal_code}`}
                    </p>
                  </InfoItem>
                </>
              )}
              {quoteData.insurance_request.commercial_company && (
                <InfoItem>
                  <h4>Ragione Sociale :</h4>
                  <p>
                    {
                      quoteData.insurance_request.commercial_company
                        .business_name
                    }
                  </p>
                </InfoItem>
              )}

              <InfoItem>
                <h4>Frequeza Pagamento:</h4>
                <p>
                  {quoteData.quotation.payment_frequency === 1
                    ? 'Annuo'
                    : 'Semestrale'}
                </p>
              </InfoItem>
              {rc_quotation.payment_frequency === 2
                && rc_quotation.amount_paid !== null && (
                  <InfoItem>
                    <h4>Scadenza:</h4>
                    <p>
                      {moment(insuranceRequest.policy_effective_date)
                        .add(1, 'year')
                        .toDate()
                        .toLocaleDateString('it-IT')}
                    </p>
                  </InfoItem>
              )}
              {rc_quotation.payment_frequency === 2
                && rc_quotation.amount_paid === null && (
                  <InfoItem>
                    <h4>Scadenza:</h4>
                    <p>
                      {moment(insuranceRequest.policy_effective_date)
                        .add(6, 'months')
                        .toDate()
                        .toLocaleDateString('it-IT')}
                    </p>
                  </InfoItem>
              )}
              {rc_quotation.payment_frequency === 1 && (
                <InfoItem>
                  <h4>Scadenza:</h4>
                  <p>
                    {moment(insuranceRequest.policy_effective_date)
                      .add(1, 'year')
                      .toDate()
                      .toLocaleDateString('it-IT')}
                  </p>
                </InfoItem>
              )}
            </InfoBoxContainer>
          </WaitingForPaymentInfo>
        </div>
        <div className="col-12 col-xxl-6 mt-2 mt-xxl-0 pagament-status-step-six">
          <WaitingForPaymentQuoteInfo>
            <QuoteInfoContainer>
              <div>
                <h3 className="ps-3 mb-4 mt-0">
                  Detagli
                  {' '}
                  <b>Pagamento</b>
                </h3>
              </div>
              <QuoteAmountTable className="table-responsive ">
                <table className="table font-smaller">
                  <thead>
                    <tr>
                      <th scope="col">Prodotto</th>
                      <th scope="col">Pagato</th>
                      <th scope="col">
                        {' '}
                        {rc_quotation.amount_paid === null
                          ? 'Primo Pagamento'
                          : 'Secondo Pagamento'}
                      </th>
                      <th scope="col">Premio</th>
                    </tr>
                  </thead>
                  <tbody>
                    {rc_quotation.product.id === 8 ? (
                      rc_quotation !== null
                      && rc_quotation.quotation_data_travel_europeassistance.quotation_data_travel_europeassistance_coverages.map(
                        (coverage, index) => (
                          <React.Fragment key={index}>
                            {coverage.enabled === 0
                              && coverage.group_id === null && (
                                <tr key={index}>
                                  <th>{coverage.title}</th>
                                  <td>
                                    {rc_quotation.amount_paid === null
                                      ? '0.00'
                                      : coverage.coverage_amount_gros
                                      / rc_quotation.payment_frequency}
                                    {' '}
                                    €
                                  </td>
                                  <td>
                                    {parseFloat(
                                      coverage.coverage_amount_gros,
                                    ).toFixed(2)}
                                    &euro;
                                  </td>
                                  <td>
                                    {parseFloat(
                                      coverage.coverage_amount_gros,
                                    ).toFixed(2)}
                                    {' '}
                                    &euro;
                                  </td>
                                </tr>
                            )}
                          </React.Fragment>
                        ),
                      )
                    ) : (
                      <tr>
                        <th scope="row">{rc_quotation.product.name}</th>
                        <td>
                          {parseFloat(
                            rc_quotation.amount_paid
                              ? rc_quotation.amount_paid
                              : 0,
                          ).toFixed(2)}
                          &euro;
                        </td>
                        <td>
                          {rc_quotation.amount_paid
                            ? (
                              parseFloat(rc_quotation.amount).toFixed(2)
                              - parseFloat(rc_quotation.amount_paid).toFixed(2)
                            ).toFixed(2)
                            : parseFloat(
                              rc_quotation.amount
                              / rc_quotation.payment_frequency,
                            ).toFixed(2)}
                          &euro;
                        </td>
                        <td>
                          {parseFloat(rc_quotation.amount).toFixed(2)}
                          &euro;
                        </td>
                      </tr>
                    )}
                    {risk_quotations
                      && risk_quotations.map((riskQuote, index) => (
                        <tr key={index}>
                          <th scope="row">
                            {riskQuote.risk_information
                              ? riskQuote.risk_information.product_name
                              : quotationTypes.find(
                                (q) => q.id === riskQuote.quote_type,
                              ) !== undefined
                                ? quotationTypes.find(
                                  (q) => q.id === riskQuote.quote_type,
                                ).name
                                : ''}
                          </th>
                          <td>
                            {parseFloat(
                              riskQuote.amount_paid ? riskQuote.amount_paid : 0,
                            ).toFixed(2)}
                            &euro;
                          </td>
                          <td>
                            {riskQuote.amount_paid
                              ? (
                                parseFloat(riskQuote.amount).toFixed(2)
                                - parseFloat(riskQuote.amount_paid).toFixed(2)
                              ).toFixed(2)
                              : parseFloat(
                                riskQuote.amount / riskQuote.payment_frequency,
                              ).toFixed(2)}
                            &euro;
                          </td>
                          <td>
                            {parseFloat(riskQuote.amount).toFixed(2)}
                            &euro;
                          </td>
                        </tr>
                      ))}
                    {rc_quotation !== null
                      && rc_quotation.company_id === 2
                      && rc_quotation.guarantees.map((guarantee, index) => (
                        <tr key={index}>
                          <th scope="row">
                            {
                              primaQuoteTypes.find(
                                (type) => type.id === guarantee.guarantees_slug,
                              ).name
                            }
                          </th>
                          <td>
                            {' '}
                            {rc_quotation.amount_paid === null
                              ? '0'
                              : guarantee.amount
                              / rc_quotation.payment_frequency}
                            {' '}
                            €
                          </td>
                          <td>
                            {' '}
                            {parseFloat(
                              guarantee.amount / rc_quotation.payment_frequency,
                            ).toFixed(2)}
                            {' '}
                            €
                          </td>
                          <td>
                            {' '}
                            {parseFloat(guarantee.amount).toFixed(2)}
                            {' '}
                            €
                          </td>
                        </tr>
                      ))}
                    {rc_quotation !== null
                      && rc_quotation.product_id === 8
                      && rc_quotation.guarantees.map((guarantee, index) => (
                        <tr key={index}>
                          <th scope="row">
                            {guarantee.guarantees_description}
                          </th>
                          <td>
                            {' '}
                            {rc_quotation.amount_paid === null
                              ? '0.00'
                              : guarantee.amount
                              / rc_quotation.payment_frequency}
                            {' '}
                            €
                          </td>
                          <td>
                            {' '}
                            {parseFloat(
                              guarantee.amount / rc_quotation.payment_frequency,
                            ).toFixed(2)}
                            {' '}
                            €
                          </td>
                          <td>
                            {' '}
                            {parseFloat(guarantee.amount).toFixed(2)}
                            {' '}
                            €
                          </td>
                        </tr>
                      ))}
                  </tbody>
                  <tfoot>
                    {!hideBrokerageAndService
                      && rc_quotation.amount_paid === null && (
                        <>
                          <tr>
                            <th scope="row" colSpan="3">
                              Brokeraggio
                            </th>
                            <td className="fw-bold">
                              {rc_quotation.brokerage_amount.toFixed(2)}
                              &euro;
                            </td>
                          </tr>
                          <tr>
                            <th scope="row" colSpan="3">
                              Servizio
                            </th>
                            <td className="fw-bold">
                              {(rc_quotation.quotation_services.length > 0
                                ? rc_quotation.quotation_services[0]
                                  .amount_gross
                                : 0
                              ).toFixed(2)}
                              &euro;
                            </td>
                          </tr>
                        </>
                    )}
                    <tr>
                      <th scope="row" colSpan="3">
                        Total
                      </th>
                      <td className="fw-bold">
                        {getTotalAmount()}
                        &euro;
                      </td>
                    </tr>
                  </tfoot>
                </table>
              </QuoteAmountTable>
            </QuoteInfoContainer>
          </WaitingForPaymentQuoteInfo>
        </div>
      </div>

      {rc_quotation.with_compensation === 0 && (
        <>
          {quoteData.quotation_payments.length !== 0 && (
            <div className="row mt-3 ">
              <div className="col-12 col-xxl-12">
                <PayByCardContainer>
                  {rc_quotation.with_compensation === 0 && (
                    <>
                      {quoteData.quotation_payments.length !== 0 && (
                        <div>
                          <h3>Stato del pagamento</h3>
                          {quoteData.quotation_payments.map((payment) => (
                            <div className="payment-record" key={payment.id}>
                              <div className="field-row">
                                <div className="field-name">
                                  <b>Carta</b>
                                </div>
                                <div className="field-value">
                                  ************
                                  {payment.last_four}
                                </div>
                              </div>
                              <div className="field-row">
                                <div className="field-name">
                                  <b>Stato</b>
                                </div>
                                <div className="field-value">
                                  <span
                                    className={
                                      payment.status_name === 'In attesa'
                                        ? 'badge bg-warning text-dark'
                                        : payment.status_name === 'Rifiutato'
                                          ? 'badge bg-danger'
                                          : 'badge bg-success'
                                    }
                                  >
                                    {payment.status_name}
                                  </span>
                                </div>
                              </div>
                              <div className="field-row">
                                <div className="field-name">
                                  <b>Pagato</b>
                                </div>
                                <div className="field-value">
                                  {payment.amount.toLocaleString()}
                                  {' '}
                                  &euro;
                                </div>
                              </div>
                              <div className="field-row">
                                <div className="field-name">
                                  <b>Ora</b>
                                </div>
                                <div className="field-value">
                                  {moment(payment.created_at).format(
                                    'DD.MM.YYYY hh:mm',
                                  )}
                                </div>
                              </div>
                              {/* {payment.status === 1 &&
                         payment.user_redirect !== null && (
                           <div className="field-row">
                             <a target="_blank" href={payment.user_redirect}>
                               <div className="btn btn-success">
                                 Autorizza il pagamento
                               </div>
                             </a>
                           </div>
                         )} */}
                            </div>
                          ))}
                        </div>
                      )}
                    </>
                  )}
                </PayByCardContainer>
              </div>
            </div>
          )}
        </>
      )}

      <div className="row mt-3">
        <div className="col-12 col-xxl-6 pagament-status-step-seven">
          <PayByCardContainer>
            <h3>
              Paga con
              {' '}
              <b>carta</b>
              {' '}
              (
              {get_amount_to_pay()}
              {' '}
              &euro;)
            </h3>
            <form onSubmit={handleSubmit(submitData)}>
              <FormInputGroup>
                <label htmlFor="">Carta di credito o di debito</label>
                <input
                  type="text"
                  {...register('card_number')}
                  placeholder="Inserisci il numero della carta"
                />
                <ErrorMessage>{errors.card_number?.message}</ErrorMessage>
              </FormInputGroup>
              <FormInputGroupContainer>
                <FormInputGroup>
                  <label htmlFor="">Data di scadenza</label>
                  <Controller
                    name="card_expiry"
                    control={control}
                    render={({ field }) => (
                      <input
                        type="text"
                        {...field}
                        placeholder="MM/AA"
                        maxLength="5"
                        onChange={handleInputChange}
                      />
                    )}
                  />
                  <ErrorMessage>{errors.card_expiry?.message}</ErrorMessage>
                </FormInputGroup>
                <FormInputGroup>
                  <label htmlFor="">Codice di sicurezza (CVV)</label>
                  <input
                    type="text"
                    {...register('card_cvv')}
                    placeholder="CVV"
                  />
                  <ErrorMessage>{errors.card_cvv?.message}</ErrorMessage>
                </FormInputGroup>
              </FormInputGroupContainer>
              <WarningInfo>
                <img src={warningIcon} alt="" />
                <p>
                  Se si fa clic su Invia verrà visualizzata una modalità di
                  conferma, dopo la conferma attendere un paio di minuti per
                  l'elaborazione. Grazie!
                </p>
              </WarningInfo>
              <PayNowButton>Procedi con il pagamento</PayNowButton>
            </form>
          </PayByCardContainer>
        </div>
        <div className="col-12 col-xxl-6 mt-2 mt-xxl-0 pagament-status-step-eight">
          <PayByBankTransfer>
            <h3>
              Paga con
              {' '}
              <b>bonifico</b>
              {' '}
              (
              {get_amount_to_pay()}
              {' '}
              &euro;)
            </h3>
            {rc_quotation.payment_proofs.map((pp, index) => (
              <PaymentProofStyled key={pp.id}>
                <h6>
                  Pagamento
                  {' '}
                  {index + 1}
                  {' '}
                  : Pagato:
                  {' '}
                  {pp.amount}
                  {' '}
                  &euro;
                </h6>

                {(totalPaymentsRequired === 1
                  || (totalPaymentsRequired === 2
                    && paymentsMadeWithBonifico - paymentMadeCount + 1
                    === index)
                  || (totalPaymentsRequired === 2 && paymentMadeCount === 0)) && (
                    <>
                      {pp.payment_proof_valid === 0 && (
                        <div className="alert alert-danger">
                          La prova del pagamento non e valida
                        </div>
                      )}
                      {pp.payment_proof_valid === 1 && (
                        <div className="alert alert-success">
                          La prova del pagamento e valida
                        </div>
                      )}

                      {pp.payment_proof_file !== null
                        && pp.payment_proof_valid === null && (
                          <div className="alert alert-warning">
                            Stiamo verificando la prova del pagemento
                          </div>
                      )}
                      <embed
                        type={pp.file_type}
                        style={{
                          marginTop: '10px',
                          width: '100%',
                          minHeight: '250px',
                        }}
                        src={`data:${pp.file_type};base64,${pp.payment_proof_file}`}
                      />
                    </>
                )}
              </PaymentProofStyled>
            ))}
            {rc_quotation.payment_proofs.find(
              (pp) => pp.payment_proof_valid === null,
            ) === undefined && (
            <>
              <BankTransferInfo>
                <BankTransferInfoItem>
                  Iban:
                  {' '}
                  <span>IT60B0326803210052864099270</span>
                </BankTransferInfoItem>
                <BankTransferInfoItem>
                  Titolare:
                  {' '}
                  <span>Greenia srl</span>
                </BankTransferInfoItem>
                <BankTransferInfoItem>
                  Causale:
                  {' '}
                  <span>
                    Pagamento polizza
                    {' '}
                    {quoteData.insurance_request.customer
                          && (quoteData.insurance_request.customer.gender !== 'G'
                            ? `${quoteData.insurance_request.customer.name} ${quoteData.insurance_request.customer.surname}`
                            : quoteData.insurance_request.customer.business_name)}
                    {quoteData.insurance_request.commercial_company
                          && quoteData.insurance_request.commercial_company
                            .business_name}
                  </span>
                </BankTransferInfoItem>
              </BankTransferInfo>
              <UploaderBonifico
                note="CARICA PROVA DEL PAGAMENTO"
                onFileUpload={(file) => uploadPaymentProf(file)}
              />
              {user.compensations === 1 && (
              <div className="row justify-content-center footer-button">
                <button
                  className="btn btn-outline-success"
                  onClick={() => compensationConfirmAlertHandle()}
                >
                  Paga con compensazione
                </button>
              </div>
              )}
            </>
            )}
          </PayByBankTransfer>
        </div>
      </div>
      {paymentAuthorizationUrl && (
        <div className="x-modal" id="fastquote-modal">
          <div
            className="x-modal-cover"
            onClick={() => setPaymentAuthorizationUrl(null)}
          />
          <div className="general-modal-content">
            <span
              className="general-modal-close"
              title="Quidi"
              onClick={() => setPaymentAuthorizationUrl(null)}
            >
              <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 512 512">
                <path d="M175 175C184.4 165.7 199.6 165.7 208.1 175L255.1 222.1L303 175C312.4 165.7 327.6 165.7 336.1 175C346.3 184.4 346.3 199.6 336.1 208.1L289.9 255.1L336.1 303C346.3 312.4 346.3 327.6 336.1 336.1C327.6 346.3 312.4 346.3 303 336.1L255.1 289.9L208.1 336.1C199.6 346.3 184.4 346.3 175 336.1C165.7 327.6 165.7 312.4 175 303L222.1 255.1L175 208.1C165.7 199.6 165.7 184.4 175 175V175zM512 256C512 397.4 397.4 512 256 512C114.6 512 0 397.4 0 256C0 114.6 114.6 0 256 0C397.4 0 512 114.6 512 256zM256 48C141.1 48 48 141.1 48 256C48 370.9 141.1 464 256 464C370.9 464 464 370.9 464 256C464 141.1 370.9 48 256 48z" />
              </svg>
            </span>
            <div className="modal-header">
              <h5 className="modal-title">Autorizza il pagamento</h5>
            </div>
            <div className="x-modal-body">
              <iframe
                title="Authorization frame"
                style={{ width: '100%', height: '70vh' }}
                src={paymentAuthorizationUrl}
              />
            </div>
            <div className="modal-footer">
              <button
                type="button"
                className="btn btn-secondary m-2"
                onClick={() => setPaymentAuthorizationUrl(null)}
              >
                Chiudi
              </button>
            </div>
          </div>
        </div>
      )}
    </>
  );
}

export default WaitingForPaymentStatus;
