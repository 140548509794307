import React, {
  createRef, useEffect, useRef, useState,
} from 'react';
import { generalAlertError, generalAlertSuccessToast } from '../../utils/alerts';
import { apiOptionalDocumentMethodManualUpload, documentMethodManualUpload } from '../../../services/axios-client/axeCommons';
import { ActionsWrapperResultPage } from '../redesignComponents/Purchase/SignatureType.styles';
import uploadIcon from '../../../assets/redesign_icons/upload.svg';
import pdfIMG from '../../../assets/images/purchase/pdf.png';
import downloadIMG from '../../../assets/redesign_icons/download.svg';
import axe from '../../../services/axios-client/axiosClient';
import { downloadFile } from '../../../services/axios-client/axeHelpers';

function CompletedScreenSaluteDocuments({
  insuranceRequest, downloadDocument, quote, collectQuoteData, documentsRequests, downloadOptionalDocument, rc_quotation,
}) {
  const uploadPolizzaEmessa = useRef(null); // 13 - Polizza emessa
  const uploadQuietanzaSemestrale = useRef(null); // 32 - Quietanza Semestrale
  const uploadPrivacyRef = useRef(null); // 12 - Privacy Greenia
  const uploadPreContractRef = useRef(null); // 29 - Percontrattuale Greenia
  const uploadDichiarazioniDocRef = useRef(null); // 28 - Dichiarazioni del contraente
  const uploadQuestionarioAutoDocRef = useRef(null); // 30 - Questionario Auto
  const uploadMergedDocFileRef = useRef(null); // 34 - Questionario Auto
  const [optionalRefs, setOptionalRefs] = useState({});

  const fireSignedDocUpload = (typeId, docId) => {
    if ([13, 35].includes(typeId)) {
      uploadPolizzaEmessa.current.click();
    }
    if (typeId === 32) {
      uploadQuietanzaSemestrale.current.click();
    }
    if (typeId === 29) {
      uploadPreContractRef.current.click();
    }

    if (typeId === 12) {
      uploadPrivacyRef.current.click();
    }

    if (typeId === 26) {
      optionalRefs[docId].current.click();
    }

    if (typeId === 28) {
      uploadDichiarazioniDocRef.current.click();
    }

    if (typeId === 30) {
      uploadQuestionarioAutoDocRef.current.click();
    }

    if (typeId === 34) {
      uploadMergedDocFileRef.current.click();
    }
  };

  const uploadSignedOptionalDocument = async (documentId, file) => {
    const uploadedFile = file[0];
    const reader = new FileReader();
    reader.readAsDataURL(uploadedFile);
    if (uploadedFile.type !== 'application/pdf') {
      generalAlertError('Formato di file errato. Il file deve essere in .pdf');
      return;
    }
    reader.onload = () => {
      apiOptionalDocumentMethodManualUpload(quote.id, documentId, {
        document: reader.result.replace('data:application/pdf;base64,', ''),
      }).then(() => {
        collectQuoteData();
        generalAlertSuccessToast('File caricato');
      });
    };
  };

  const uploadSignedDocument = async (documentId, files) => {
    const uploadedFile = files[0];
    const reader = new FileReader();

    reader.readAsDataURL(uploadedFile);
    if (uploadedFile.type !== 'application/pdf') {
      generalAlertError('Formato di file errato. Il file deve essere in .pdf');
      return;
    }
    reader.onload = () => {
      documentMethodManualUpload(quote.id, documentId, {
        document: reader.result.replace('data:application/pdf;base64,', ''),
      }).then(() => {
        collectQuoteData();
        generalAlertSuccessToast('File caricato');
      });
    };
  };

  const downloadInvoice = async (quoteId) => {
    const res = await axe.get(`/list/invoices/${quoteId}/download`, {
      responseType: 'blob',
    });
    const contentDisposition = res.headers['content-disposition'];
    const file_name = contentDisposition.split('filename="')[1].split('"')[0];
    downloadFile(res.data, file_name);
  };

  let initialQuotationDocumentRequests = [];

  if (quote.initial_quotation !== undefined) {
    initialQuotationDocumentRequests = [
      ...quote.initial_quotation.insurance_request.document_requests.filter(
        (dr) => dr.document !== null,
      ),
      ...quote.initial_quotation.document_requests.filter(
        (dr) => dr.document !== null,
      ),
    ];
  }

  useEffect(() => {
    const optionalDocs = {};
    documentsRequests
      .filter((d) => d.optional_document_request_id !== undefined)
      .forEach((doc) => {
        optionalDocs[doc.id] = createRef(null);
      });

    setOptionalRefs(optionalDocs);
  }, [JSON.stringify(documentsRequests)]);

  return (
    <>
      <div className="col-12 col-md-12">
        <div className="card border mb-2 polizze-emessa-status-step-four">
          <div className="card-header bg-secondary text-white">
            <h2 className="h3 mb-0 card-title">Documenti</h2>
          </div>
          <div className="card-body">
            <ol className="list-group list-group-numbered">
              {insuranceRequest.documents
                .filter(
                  (dr) => dr.document_type_id !== 34 && dr.document_type_id !== 35 && dr.document_type_id !== 36 && dr.document_type_id !== 37 && dr.document_type_id !== 38 && dr.document_type_id !== 39 && dr.document_type_id !== 40 && dr.document_type_id !== 41,
                )
                .map((document, index) => (
                  <li
                    className="list-group-item d-flex justify-content-between align-items-start position-relative"
                    key={index}
                  >
                    <div className="ms-2 me-auto">
                      <div className="fw-bold mb-2">
                        {document.document_type_id === 13
                        && insuranceRequest.quotation_renewal_request !== null
                          ? 'Rinnovo'
                          : document.document_type.name}
                      </div>
                      <div className="d-flex align-items-center justify-content-between">
                        <img
                          src={pdfIMG}
                          className="img-fluid"
                          width="25"
                          alt="PDF"
                        />
                        <div className="mx-2">
                          <h5 className="h6 mb-0">
                            {document.original_filename}
                          </h5>
                        </div>
                        <div className="d-flex align-items-center justify-content-center">
                          <button
                            className="doc-btn border-0 bg-transparent"
                            onClick={() => downloadDocument(document.id)}
                          >
                            <img src={downloadIMG} width="25" alt="" />
                          </button>
                        </div>
                        {document.signable_document === 1
                          && (document.is_signed_valid === 0
                            || (document.is_signed_valid === null
                              && document.signed_document_filename === null)) && (
                              <ActionsWrapperResultPage>
                                {' '}
                                {[13, 35].includes(document.document_type.id) && (
                                <div
                                  className="action-button"
                                  onClick={() => fireSignedDocUpload(
                                    document.document_type_id,
                                  )}
                                >
                                  <img src={uploadIcon} alt="" width={25} />
                                  <input
                                    ref={uploadPolizzaEmessa}
                                    type="file"
                                    name=""
                                    id=""
                                    className="d-none"
                                    onChange={(e) => uploadSignedDocument(
                                      document.id,
                                      e.target.files,
                                    )}
                                  />
                                </div>
                                )}
                                {document.document_type.id === 32 && (
                                <div
                                  className="action-button"
                                  onClick={() => fireSignedDocUpload(
                                    document.document_type_id,
                                  )}
                                >
                                  <img src={uploadIcon} alt="" width={25} />
                                  <input
                                    ref={uploadQuietanzaSemestrale}
                                    type="file"
                                    name=""
                                    id=""
                                    className="d-none"
                                    onChange={(e) => uploadSignedDocument(
                                      document.id,
                                      e.target.files,
                                    )}
                                  />
                                </div>
                                )}
                                {document.document_type.id === 29 && (
                                <div
                                  className="action-button"
                                  onClick={
                                    document.signed_document_filename === null
                                      ? () => fireSignedDocUpload(
                                        document.document_type.id,
                                      )
                                      : () => {}
                                  }
                                >
                                  <img
                                    src={uploadIcon}
                                    alt="Carica"
                                    width={25}
                                  />
                                  <input
                                    ref={uploadPreContractRef}
                                    type="file"
                                    name=""
                                    id=""
                                    className="d-none"
                                    onChange={(e) => uploadSignedDocument(
                                      document.id,
                                      e.target.files,
                                    )}
                                  />
                                </div>
                                )}
                                {document.document_type.id === 28 && (
                                <div
                                  className="action-button"
                                  onClick={
                                    document.signed_document_filename === null
                                      ? () => fireSignedDocUpload(
                                        document.document_type.id,
                                      )
                                      : () => {}
                                  }
                                >
                                  <img
                                    src={uploadIcon}
                                    alt="Carica"
                                    width={25}
                                  />
                                  <input
                                    ref={uploadDichiarazioniDocRef}
                                    type="file"
                                    name=""
                                    id=""
                                    className="d-none"
                                    onChange={(e) => uploadSignedDocument(
                                      document.id,
                                      e.target.files,
                                    )}
                                  />
                                </div>
                                )}
                                {document.document_type.id === 30 && (
                                <div
                                  className="action-button"
                                  onClick={
                                    document.signed_document_filename === null
                                      ? () => fireSignedDocUpload(
                                        document.document_type.id,
                                      )
                                      : () => {}
                                  }
                                >
                                  <img
                                    src={uploadIcon}
                                    alt="Carica"
                                    width={25}
                                  />
                                  <input
                                    ref={uploadQuestionarioAutoDocRef}
                                    type="file"
                                    name=""
                                    id=""
                                    className="d-none"
                                    onChange={(e) => uploadSignedDocument(
                                      document.id,
                                      e.target.files,
                                    )}
                                  />
                                </div>
                                )}
                                {document.document_type.id === 34 && (
                                <div
                                  className="action-button"
                                  onClick={
                                    document.signed_document_filename === null
                                      ? () => fireSignedDocUpload(
                                        document.document_type.id,
                                      )
                                      : () => {}
                                  }
                                >
                                  <img
                                    src={uploadIcon}
                                    alt="Carica"
                                    width={25}
                                  />
                                  <input
                                    ref={uploadMergedDocFileRef}
                                    type="file"
                                    name=""
                                    id=""
                                    className="d-none"
                                    onChange={(e) => uploadSignedDocument(
                                      document.id,
                                      e.target.files,
                                    )}
                                  />
                                </div>
                                )}
                                {document.optional_document_request_id && (
                                <div
                                  className="action-button"
                                  onClick={
                                    document.signed_document_filename === null
                                      ? () => fireSignedDocUpload(
                                        document.document_type.id,
                                        document.id,
                                      )
                                      : () => {}
                                  }
                                >
                                  <img
                                    src={uploadIcon}
                                    alt="Carica"
                                    width={25}
                                  />
                                  <input
                                    ref={optionalRefs[document.id]}
                                    type="file"
                                    name=""
                                    id=""
                                    className="d-none"
                                    onChange={(e) => uploadSignedOptionalDocument(
                                      document.id,
                                      e.target.files,
                                    )}
                                  />
                                </div>
                                )}
                                {document.document_type.id === 12 && (
                                <div
                                  className="action-button"
                                  onClick={
                                    document.signed_document_filename === null
                                      ? () => fireSignedDocUpload(
                                        document.document_type.id,
                                      )
                                      : () => {}
                                  }
                                >
                                  <img
                                    src={uploadIcon}
                                    alt="Carica"
                                    width={25}
                                  />
                                  <input
                                    ref={uploadPrivacyRef}
                                    type="file"
                                    name=""
                                    id=""
                                    className="d-none"
                                    onChange={(e) => uploadSignedDocument(
                                      document.id,
                                      e.target.files,
                                    )}
                                  />
                                </div>
                                )}
                              </ActionsWrapperResultPage>
                        )}
                      </div>
                      {document.signable_document === 1
                        && document.is_signed_valid === 0
                        && document.signed_document_filename === null
                        && document.motivation !== null && (
                          <p className="text-danger mt-2">
                            {document.motivation}
                          </p>
                      )}
                    </div>
                    <div>
                      {document.signable_document === 1 ? (
                        document.is_signed_valid === null
                        && document.signed_document_filename !== null ? (
                          <span className="badge bg-warning rounded-pill position-absolute top-25 end-0 me-2">
                            In Verifica
                          </span>
                          ) : document.is_signed_valid === 0 ? (
                            <span className="badge bg-danger rounded-pill position-absolute top-25 end-0 me-2">
                              Firma Non Valida
                            </span>
                          ) : document.is_signed_valid === null
                          && document.signed_document_filename === null ? (
                            <span className="badge bg-warning rounded-pill position-absolute top-25 end-0 me-2">
                              Da Firmare
                            </span>
                            ) : document.is_signed_valid === 1
                          && document.signed_document_filename !== null ? (
                            <span className="badge bg-primary rounded-pill position-absolute top-25 end-0 me-2">
                              Firmato
                            </span>
                              ) : null
                      ) : (
                        <span className="badge bg-danger rounded-pill position-absolute top-25 end-0 me-2">
                          Non Richiede Firma
                        </span>
                      )}
                      {' '}
                    </div>
                  </li>
                ))}
              {rc_quotation.documents.map((document, index) => (
                <li
                  className="list-group-item d-flex justify-content-between align-items-start"
                  key={index}
                >
                  <div className="ms-2 me-auto">
                    <div className="fw-bold mb-2">
                      {document.document_type.name}
                    </div>
                    <div className="d-flex align-items-center justify-content-between">
                      <img
                        src={pdfIMG}
                        className="img-fluid"
                        width="25"
                        alt="PDF"
                      />
                      <div className="mx-2">
                        <h5 className="h6 mb-0">
                          {document.original_filename}
                        </h5>
                      </div>
                      <div className="d-flex align-items-center justify-content-center">
                        <button
                          className="doc-btn border-0 bg-transparent"
                          onClick={() => downloadDocument(document.id)}
                        >
                          <img src={downloadIMG} width="25" alt="" />
                        </button>
                      </div>
                      {document.signable_document === 1
                        && (document.is_signed_valid === 0
                          || (document.is_signed_valid === null
                            && document.signed_document_filename === null)) && (
                            <ActionsWrapperResultPage>
                              {' '}
                              {document.document_type.id === 13 && (
                              <div
                                className="action-button"
                                onClick={() => fireSignedDocUpload(document.document_type_id)}
                              >
                                <img src={uploadIcon} alt="" width={25} />
                                <input
                                  ref={uploadPolizzaEmessa}
                                  type="file"
                                  name=""
                                  id=""
                                  className="d-none"
                                  onChange={(e) => uploadSignedDocument(
                                    document.id,
                                    e.target.files,
                                  )}
                                />
                              </div>
                              )}
                              {document.document_type.id === 32 && (
                              <div
                                className="action-button"
                                onClick={() => fireSignedDocUpload(document.document_type_id)}
                              >
                                <img src={uploadIcon} alt="" width={25} />
                                <input
                                  ref={uploadQuietanzaSemestrale}
                                  type="file"
                                  name=""
                                  id=""
                                  className="d-none"
                                  onChange={(e) => uploadSignedDocument(
                                    document.id,
                                    e.target.files,
                                  )}
                                />
                              </div>
                              )}
                              {document.document_type.id === 29 && (
                              <div
                                className="action-button"
                                onClick={
                                  document.signed_document_filename === null
                                    ? () => fireSignedDocUpload(
                                      document.document_type.id,
                                    )
                                    : () => {}
                                }
                              >
                                <img src={uploadIcon} alt="Carica" width={25} />
                                <input
                                  ref={uploadPreContractRef}
                                  type="file"
                                  name=""
                                  id=""
                                  className="d-none"
                                  onChange={(e) => uploadSignedDocument(
                                    document.id,
                                    e.target.files,
                                  )}
                                />
                              </div>
                              )}
                              {document.document_type.id === 28 && (
                              <div
                                className="action-button"
                                onClick={
                                  document.signed_document_filename === null
                                    ? () => fireSignedDocUpload(
                                      document.document_type.id,
                                    )
                                    : () => {}
                                }
                              >
                                <img src={uploadIcon} alt="Carica" width={25} />
                                <input
                                  ref={uploadDichiarazioniDocRef}
                                  type="file"
                                  name=""
                                  id=""
                                  className="d-none"
                                  onChange={(e) => uploadSignedDocument(
                                    document.id,
                                    e.target.files,
                                  )}
                                />
                              </div>
                              )}
                              {document.document_type.id === 30 && (
                              <div
                                className="action-button"
                                onClick={
                                  document.signed_document_filename === null
                                    ? () => fireSignedDocUpload(
                                      document.document_type.id,
                                    )
                                    : () => {}
                                }
                              >
                                <img src={uploadIcon} alt="Carica" width={25} />
                                <input
                                  ref={uploadQuestionarioAutoDocRef}
                                  type="file"
                                  name=""
                                  id=""
                                  className="d-none"
                                  onChange={(e) => uploadSignedDocument(
                                    document.id,
                                    e.target.files,
                                  )}
                                />
                              </div>
                              )}
                              {document.optional_document_request_id && (
                              <div
                                className="action-button"
                                onClick={
                                  document.signed_document_filename === null
                                    ? () => fireSignedDocUpload(
                                      document.document_type.id,
                                      document.id,
                                    )
                                    : () => {}
                                }
                              >
                                <img src={uploadIcon} alt="Carica" width={25} />
                                <input
                                  ref={optionalRefs[document.id]}
                                  type="file"
                                  name=""
                                  id=""
                                  className="d-none"
                                  onChange={(e) => uploadSignedOptionalDocument(
                                    document.id,
                                    e.target.files,
                                  )}
                                />
                              </div>
                              )}
                              {document.document_type.id === 12 && (
                              <div
                                className="action-button"
                                onClick={
                                  document.signed_document_filename === null
                                    ? () => fireSignedDocUpload(
                                      document.document_type.id,
                                    )
                                    : () => {}
                                }
                              >
                                <img src={uploadIcon} alt="Carica" width={25} />
                                <input
                                  ref={uploadPrivacyRef}
                                  type="file"
                                  name=""
                                  id=""
                                  className="d-none"
                                  onChange={(e) => uploadSignedDocument(
                                    document.id,
                                    e.target.files,
                                  )}
                                />
                              </div>
                              )}
                            </ActionsWrapperResultPage>
                      )}
                    </div>
                    {document.signable_document === 1
                      && document.is_signed_valid === 0
                      && document.signed_document_filename === null
                      && document.motivation !== null && (
                        <p className="text-danger mt-2">
                          {document.motivation}
                        </p>
                    )}
                  </div>
                  <div>
                    {document.signable_document === 1 ? (
                      document.is_signed_valid === null
                      && document.signed_document_filename !== null ? (
                        <span className="badge bg-warning rounded-pill position-absolute top-25 end-0 me-2">
                          In Verifica
                        </span>
                        ) : document.is_signed_valid === 0 ? (
                          <span className="badge bg-danger rounded-pill position-absolute top-25 end-0 me-2">
                            Firma Non Valida
                          </span>
                        ) : document.is_signed_valid === null
                        && document.signed_document_filename === null ? (
                          <span className="badge bg-warning rounded-pill position-absolute top-25 end-0 me-2">
                            Da Firmare
                          </span>
                          ) : document.is_signed_valid === 1
                        && document.signed_document_filename !== null ? (
                          <span className="badge bg-primary rounded-pill position-absolute top-25 end-0 me-2">
                            Firmato
                          </span>
                            ) : null
                    ) : (
                      <span className="badge bg-danger rounded-pill position-absolute top-25 end-0 me-2">
                        Non Richiede Firma
                      </span>
                    )}
                    {' '}
                  </div>
                </li>
              ))}

              {documentsRequests.map((dr, index) => (
                <li
                  className="list-group-item d-flex justify-content-between align-items-start"
                  key={index}
                >
                  <div className="ms-2 me-auto">
                    <div className="fw-bold mb-2">
                      {dr.optional_document_type.name}
                    </div>
                    <div className="d-flex align-items-center justify-content-between">
                      <img
                        src={pdfIMG}
                        className="img-fluid"
                        width="25"
                        alt="PDF"
                      />
                      <div className="mx-2">
                        <h5 className="h6 mb-0">
                          {dr.document.original_filename}
                        </h5>
                      </div>
                      <div className="d-flex align-items-center justify-content-center">
                        <button
                          className="doc-btn border-0 bg-transparent"
                          onClick={() => downloadOptionalDocument(dr.document.id)}
                        >
                          <img src={downloadIMG} width="25" alt="" />
                        </button>
                      </div>
                      {document.signable_document === 1
                        && (document.is_signed_valid === 0
                          || (document.is_signed_valid === null
                            && document.signed_document_filename === null)) && (
                            <ActionsWrapperResultPage>
                              {' '}
                              {document.document_type.id === 13 && (
                              <div
                                className="action-button"
                                onClick={() => fireSignedDocUpload(document.document_type_id)}
                              >
                                <img src={uploadIcon} alt="" width={25} />
                                <input
                                  ref={uploadPolizzaEmessa}
                                  type="file"
                                  name=""
                                  id=""
                                  className="d-none"
                                  onChange={(e) => uploadSignedDocument(
                                    document.id,
                                    e.target.files,
                                  )}
                                />
                              </div>
                              )}
                              {document.document_type.id === 32 && (
                              <div
                                className="action-button"
                                onClick={() => fireSignedDocUpload(document.document_type_id)}
                              >
                                <img src={uploadIcon} alt="" width={25} />
                                <input
                                  ref={uploadQuietanzaSemestrale}
                                  type="file"
                                  name=""
                                  id=""
                                  className="d-none"
                                  onChange={(e) => uploadSignedDocument(
                                    document.id,
                                    e.target.files,
                                  )}
                                />
                                {' '}
                              </div>
                              )}
                              {document.document_type.id === 29 && (
                              <div
                                className="action-button"
                                onClick={
                                  document.signed_document_filename === null
                                    ? () => fireSignedDocUpload(
                                      document.document_type.id,
                                    )
                                    : () => {}
                                }
                              >
                                <img src={uploadIcon} alt="Carica" width={25} />
                                <input
                                  ref={uploadPreContractRef}
                                  type="file"
                                  name=""
                                  id=""
                                  className="d-none"
                                  onChange={(e) => uploadSignedDocument(
                                    document.id,
                                    e.target.files,
                                  )}
                                />
                              </div>
                              )}
                              {document.document_type.id === 28 && (
                              <div
                                className="action-button"
                                onClick={
                                  document.signed_document_filename === null
                                    ? () => fireSignedDocUpload(
                                      document.document_type.id,
                                    )
                                    : () => {}
                                }
                              >
                                <img src={uploadIcon} alt="Carica" width={25} />
                                <input
                                  ref={uploadDichiarazioniDocRef}
                                  type="file"
                                  name=""
                                  id=""
                                  className="d-none"
                                  onChange={(e) => uploadSignedDocument(
                                    document.id,
                                    e.target.files,
                                  )}
                                />
                              </div>
                              )}
                              {document.document_type.id === 30 && (
                              <div
                                className="action-button"
                                onClick={
                                  document.signed_document_filename === null
                                    ? () => fireSignedDocUpload(
                                      document.document_type.id,
                                    )
                                    : () => {}
                                }
                              >
                                <img src={uploadIcon} alt="Carica" width={25} />
                                <input
                                  ref={uploadQuestionarioAutoDocRef}
                                  type="file"
                                  name=""
                                  id=""
                                  className="d-none"
                                  onChange={(e) => uploadSignedDocument(
                                    document.id,
                                    e.target.files,
                                  )}
                                />
                              </div>
                              )}
                              {document.optional_document_request_id && (
                              <div
                                className="action-button"
                                onClick={
                                  document.signed_document_filename === null
                                    ? () => fireSignedDocUpload(
                                      document.document_type.id,
                                      document.id,
                                    )
                                    : () => {}
                                }
                              >
                                <img src={uploadIcon} alt="Carica" width={25} />
                                <input
                                  ref={optionalRefs[document.id]}
                                  type="file"
                                  name=""
                                  id=""
                                  className="d-none"
                                  onChange={(e) => uploadSignedOptionalDocument(
                                    document.id,
                                    e.target.files,
                                  )}
                                />
                                {' '}
                              </div>
                              )}
                              {document.document_type.id === 12 && (
                              <div
                                className="action-button"
                                onClick={
                                  document.signed_document_filename === null
                                    ? () => fireSignedDocUpload(
                                      document.document_type.id,
                                    )
                                    : () => {}
                                }
                              >
                                <img src={uploadIcon} alt="Carica" width={25} />
                                <input
                                  ref={uploadPrivacyRef}
                                  type="file"
                                  name=""
                                  id=""
                                  className="d-none"
                                  onChange={(e) => uploadSignedDocument(
                                    document.id,
                                    e.target.files,
                                  )}
                                />
                                {' '}
                              </div>
                              )}
                            </ActionsWrapperResultPage>
                      )}
                    </div>
                    {document.signable_document === 1
                      && document.is_signed_valid === 0
                      && document.signed_document_filename === null
                      && document.motivation !== null && (
                        <p className="text-danger mt-2">
                          {document.motivation}
                        </p>
                    )}
                  </div>
                  <div>
                    {document.signable_document === 1 ? (
                      document.is_signed_valid === null
                      && document.signed_document_filename !== null ? (
                        <span className="badge bg-warning rounded-pill position-absolute top-25 end-0 me-2">
                          In Verifica
                        </span>
                        ) : document.is_signed_valid === 0 ? (
                          <span className="badge bg-danger rounded-pill position-absolute top-25 end-0 me-2">
                            Firma Non Valida
                          </span>
                        ) : document.is_signed_valid === null
                        && document.signed_document_filename === null ? (
                          <span className="badge bg-warning rounded-pill position-absolute top-25 end-0 me-2">
                            Da Firmare
                          </span>
                          ) : document.is_signed_valid === 1
                        && document.signed_document_filename !== null ? (
                          <span className="badge bg-primary rounded-pill position-absolute top-25 end-0 me-2">
                            Firmato
                          </span>
                            ) : null
                    ) : (
                      <span className="badge bg-danger rounded-pill position-absolute top-25 end-0 me-2">
                        Non Richiede Firma
                      </span>
                    )}
                    {' '}
                  </div>
                </li>
              ))}

              {quote.payments.length === 1 && (
              <li className="list-group-item d-flex justify-content-between align-items-start">
                <div className="ms-2 me-auto">
                  <div className="fw-bold mb-2">Fattura:</div>
                  <div className="d-flex align-items-center justify-content-between">
                    <img src={pdfIMG} className="img-fluid" width="25" alt="PDF" />
                    <div className="mx-2">
                      <h5 className="h6 mb-0">Scarica Fattura</h5>
                    </div>
                    <div className="d-flex align-items-center justify-content-center">
                      <button
                        className="doc-btn border-0 bg-transparent"
                        onClick={() => downloadInvoice(quote.id)}
                      >
                        <img src={downloadIMG} width="25" alt="" />
                      </button>
                    </div>
                  </div>
                </div>
                <div>
                  <span className="badge bg-danger rounded-pill position-absolute top-25 end-0 me-2">Non Richiede Firma</span>
                </div>
              </li>
              )}
            </ol>
          </div>
        </div>
      </div>
      {rc_quotation.initial_quotation !== undefined && (
        <div className="col-12 col-md-6">
          <div className="card border">
            <div className="card-header bg-secondary text-white">
              <h2 className="h3 mb-0 card-title">
                Documenti nel preventivo iniziale
              </h2>
            </div>
            <div className="card-body">
              <ol className="list-group list-group-numbered">
                {rc_quotation.initial_quotation.insurance_request.documents.map(
                  (document, index) => (
                    <li
                      className="list-group-item d-flex justify-content-between align-items-start"
                      key={index}
                    >
                      <div className="ms-2 me-auto">
                        <div className="fw-bold mb-2">
                          {document.document_type.name}
                        </div>
                        <div className="d-flex align-items-center justify-content-between">
                          <img
                            src={pdfIMG}
                            className="img-fluid"
                            width="25"
                            alt="PDF"
                          />
                          <div className="mx-2">
                            <h5 className="h6 mb-0">
                              {document.original_filename}
                            </h5>
                          </div>
                          <div className="d-flex align-items-center justify-content-center">
                            <button
                              className="doc-btn border-0 bg-transparent"
                              onClick={() => downloadDocument(document.id)}
                            >
                              <img src={downloadIMG} width="25" alt="" />
                            </button>
                          </div>
                        </div>
                      </div>
                      <div>
                        {document.signable_document === 1
                        && document.is_signed_valid ? (
                          <span className="badge bg-primary rounded-pill me-1">
                            Firmato
                          </span>
                          ) : (
                            <span className="badge bg-danger rounded-pill me-1">
                              Non Richiede Firma
                            </span>
                          )}
                      </div>
                    </li>
                  ),
                )}
                {rc_quotation.initial_quotation.documents.map(
                  (document, index) => (
                    <li
                      className="list-group-item d-flex justify-content-between align-items-start"
                      key={index}
                    >
                      <div className="ms-2 me-auto">
                        <div className="fw-bold mb-2">
                          {document.document_type.name}
                        </div>
                        <div className="d-flex align-items-center justify-content-between">
                          <img
                            src={pdfIMG}
                            className="img-fluid"
                            width="25"
                            alt="PDF"
                          />
                          <div className="mx-2">
                            <h5 className="h6 mb-0">
                              {document.original_filename}
                            </h5>
                          </div>
                          <div className="d-flex align-items-center justify-content-center">
                            <button
                              className="doc-btn border-0 bg-transparent"
                              onClick={() => downloadDocument(document.id)}
                            >
                              <img src={downloadIMG} width="25" alt="" />
                            </button>
                          </div>
                        </div>
                      </div>
                      <div>
                        {document.signable_document === 1
                        && document.is_signed_valid ? (
                          <span className="badge bg-primary rounded-pill me-1">
                            Firmato
                          </span>
                          ) : (
                            <span className="badge bg-danger rounded-pill me-1">
                              Non Richiede Firma
                            </span>
                          )}
                      </div>
                    </li>
                  ),
                )}

                {initialQuotationDocumentRequests.map((dr, index) => (
                  <li
                    className="list-group-item d-flex justify-content-between align-items-start"
                    key={index}
                  >
                    <div className="ms-2 me-auto">
                      <div className="fw-bold mb-2">
                        {dr.optional_document_type.name}
                      </div>
                      <div className="d-flex align-items-center justify-content-between">
                        <img
                          src={pdfIMG}
                          className="img-fluid"
                          width="25"
                          alt="PDF"
                        />
                        <div className="mx-2">
                          <h5 className="h6 mb-0">
                            {dr.document.original_filename}
                          </h5>
                        </div>
                        <div className="d-flex align-items-center justify-content-center">
                          <button
                            className="doc-btn border-0 bg-transparent"
                            onClick={() => downloadOptionalDocument(dr.document.id)}
                          >
                            <img src={downloadIMG} width="25" alt="" />
                          </button>
                        </div>
                      </div>
                    </div>
                    <div>
                      {dr.document.signable_document === 1
                      && dr.document.is_signed_valid ? (
                        <span className="badge bg-primary rounded-pill me-1">
                          Firmato
                        </span>
                        ) : (
                          <span className="badge bg-danger rounded-pill me-1">
                            Non Richiede Firma
                          </span>
                        )}
                    </div>
                  </li>
                ))}
              </ol>
            </div>
          </div>
        </div>
      )}
    </>
  );
}

export default CompletedScreenSaluteDocuments;
