import { useEffect, useState, useRef } from 'react';
import {
  useLocation, useNavigate, useParams, Navigate,
} from 'react-router-dom';
import { useSelector } from 'react-redux';
import { useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import moment from 'moment';
// import { useSetState } from 'react-use';
// import Joyride from 'react-joyride';
// import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
// import { faBook } from '@fortawesome/free-solid-svg-icons';
import {
  updateActiveCustomer,
  modifyRequestData,
} from '../services/axios-client/axeCommons';
import LoadingGif from '../assets/images/loading.gif';
import ProgressBar from '../common/components/ProgressBar';
import { validateCarPlate, validateItalianPostCode } from '../common/validators';
import { guideType } from '../common/constants';
import convertToItalianDate from '../common/helpers/convertToItalianDate';
import PageLoading from '../common/components/elements/PageLoading';
import appStorage from '../common/helpers/appStorage';
import getAllowedPolicyEffectDate from '../common/helpers/getAllowedPolicyEffectDate';
import generateFiscalCode, {
  decodeFiscalCode,
} from '../common/helpers/generateFiscalCode';
import { calculateEmptyViolationsData } from '../common/components/inputs/InputViolations';
import { useDebouncedEffect } from '../common/utils/useDebouncedEffect';
import {
  autoCancelFastQuote,
  autoChangeIndex,
  autoGetCustomerData,
  autoGetInitialData,
  autoGetQuickInfo,
  autoGetVehicleInformation,
  autoGetVehicleInformationWithSpeechToText,
  autoGetVehicleModels,
  autoGetVehicleVersions,
  autoSearchActiveClient,
  autoSubmitAnswers,
  autoUpdateFormData,
  getFastQuoteData,
  resetAutoForm,
} from '../features/forms/formsActions';
import {
  generalAlertError,
  generalAlertErrorToast,
} from '../common/utils/alerts';

import {
  answersFieldsBySteps,
  answersValidationsSchema,
} from './RcVehicle.validations';
import QuoteGenerateLoading from '../common/components/elements/QuoteGenerateLoading';
import { CenteredDivTitle } from '../common/components/CenteredPageTitle.styles';
import RcAutoStepOne from '../common/FormFunnels/Auto/RcAutoStepOne';
import RcAutoStepTwo from '../common/FormFunnels/Auto/RcAutoStepTwo';
import RcAutoStepThree from '../common/FormFunnels/Auto/RcAutoStepThree';
import RcAutoStepFour from '../common/FormFunnels/Auto/RcAutoStepFour';
import RcAutoFastQuoteModal from '../common/FormFunnels/Auto/RcAutoFastQuoteModal';
import { getAvailableProducts } from '../features/auth/authActions';
// import {
//   mountJoyrideStepsIndexFour, mountJoyrideStepsIndexOne, mountJoyrideStepsIndexThree, mountJoyrideStepsIndexTwo,
// } from '../utils/joyrideVehicleTourHelper';
// import { joyrideOptions } from '../common/utils/joyrideUtils';
// import { handleCallbackRcVehicle } from '../common/utils/joyrideCallbacks';

const Storage = appStorage();

const titles = {
  1: 'Dati del Veicolo',
  2: 'Dati Personali',
  3: 'Dati Assicurativi',
  4: 'Calcolare',
};

function RcVehicle() {
  const navigate = useNavigate();
  const location = useLocation();

  const { fastQuoteId } = useParams();
  const skipCheckGenderRef = useRef(false);
  const skipCheckInsuranceTypeRef = useRef(false);

  const { vehicle } = useParams();

  const StoredAnswers = Storage.getVehicleQuoteAnswers();
  const StoredOwner = Storage.getVehicleOwnerData();
  const StoredDriver = Storage.getVehicleDriverData();
  const StoredBersani = Storage.getBersani();

  // Constants
  const stepsQuote = [
    'Dati del Veicolo',
    'Dati Personali',
    'Dati Assicurativi',
    'Calcolare',
  ];

  const state = useSelector((store) => store.forms.auto);
  const {
    loading,
    isLoadingVehicleModels,
    isLoadingVehicleVersions,
    isSubmitting,
    index,
    states,
    municipalities,
    vehicleModels,
    dataPrivacy,
    brands,
    formData,
    vehicleVersions,
    driverForm,
    ownerForm,
    searchResults,
    selectedClientOldData,
    selectedClient,
    selectedClientId,
    isLoadingFastQuote,
    fastQuoteVehicleInfo,
    loadingQuotes,
  } = state;

  const title = titles[index];

  const [searchClient, setSearchClient] = useState('');
  const [skipQuickInfo, setSkipQuickInfo] = useState(true);
  const [proceedButton, setProceedButton] = useState(true);
  const [fastQuoteData, setFastQuoteData] = useState(null);

  const bersaniComponentRef = useRef();
  const driverFormComponentRef = useRef();
  const ownerFormComponentRef = useRef();
  const fastQuoteInfoRef = useRef();
  const confirmFastQuoteRef = useRef();

  const {
    register,
    formState: { errors, touchedFields },
    getValues,
    setValue,
    trigger,
    watch,
  } = useForm({
    mode: 'all',
    resolver: yupResolver(answersValidationsSchema),
    shouldFocusError: true,
    defaultValues: StoredAnswers || {
      product: vehicle,
      source: 'greenia.it',
      contractor_is_owner: '1',
      contractor_is_driver: '1',
      name: '',
      surname: '',
      gender: '',
      fiscal_code: '',
      phone: Storage.getUser().phone ? Storage.getUser().phone : '',
      email: '',
      date_of_birth: '',
      country_of_birth_code: 'Z000',
      province_of_birth_code: null,
      commune_of_birth_code: null,
      born_abroad: false,
      residence_province_code: '',
      residence_commune_code: '',
      postal_code: '',
      address: '',
      house_number: '',
      civil_status_id: '2',
      education_level_id: '3',
      profession_id: '1',
      driving_license_year: '',
      vehicle_plate: '',
      vehicle_brand_code: '',
      vehicle_model_code: '',
      vehicle_version_code: '',
      vehicle_am_code: '',
      imm_vehicle_year: '',
      imm_vehicle_month: '',
      inherit_merit_class: '',
      merit_class: '1',
      vehicle_purchased_month: '',
      vehicle_purchased_year: '',
      theft_protection_code: '4',
      tow_hook: '0',
      privacies: [],
      children: '0',
      vehicle_parking: '1',
      other_power_supply: '9',
      vehicle_usage: '1',
      predicted_km: 10000,
      vehicles_owned: 2,
      policy_effective_date: '',
      other_drivers: '1',
      youngest_age_driver: 30,
      youngest_age_family_member: 30,
      mofified_vehicle: 1,
      valid_driving_license: 1,
      first_insuranced_year: '',
      violations: 0,
      violations_data: calculateEmptyViolationsData(),
      violations_number: 0,
      business_name: '',
      vat_number: '',
      company_type: null,
      guide_type: 'free',
      insurance_type: '',
      complete_manually: false,
      renew_check: false,
      new_risks_check: false,
      existing_customer: false,
      active_customer_id: null,
      power: 0,
      weight: 0,
      payment_frequency: 1, // Anuuale = 1 || Semestrale = 2
    },
  });

  const answers = watch();

  const isValidPlate = async (plate) => {
    try {
      await answersValidationsSchema.validateAt('vehicle_plate', {
        vehicle_plate: plate,
      });
      return true;
    } catch (err) {
      return false;
    }
  };

  useEffect(() => {
    const values = getValues();

    if (values !== undefined) {
      const objectKeys = Object.keys(values);

      objectKeys.forEach((key) => {
        if (values[key] !== '' && values[key] !== null) {
          setValue(key, values[key], {
            shouldTouch: true,
            shouldDirty: true,
            shouldValidate: true,
          });
        }
      });
    }
  }, []);

  const isValid = (name) => {
    if (errors[name]?.message !== undefined) {
      return false;
    }

    if (touchedFields[name] === undefined) {
      return false;
    }

    return true;
  };

  const updateAnswers = (data) => {
    const keys = Object.keys(data);
    keys.forEach((key) => {
      setValue(key, data[key], {
        shouldValidate: true,
        shouldDirty: true,
        shouldTouch: true,
      });
    });
    trigger(keys);
  };

  const [skipBrandCodeEffect, setSkipBrandCodeEffect] = useState(false);
  const vehicleBrandCodeEffectDependencies = useRef();
  const vehicleBrandCodeDeps = [
    answers.vehicle_brand_code,
    answers.imm_vehicle_year,
  ];
  useEffect(() => {
    if (skipBrandCodeEffect) {
      setSkipBrandCodeEffect(false);
      return;
    }

    if (vehicleBrandCodeEffectDependencies.current === undefined) {
      vehicleBrandCodeEffectDependencies.current = JSON.stringify(vehicleBrandCodeDeps);
      return;
    }

    if (
      vehicleBrandCodeEffectDependencies.current
      === JSON.stringify(vehicleBrandCodeDeps)
    ) {
      return;
    }

    vehicleBrandCodeEffectDependencies.current = JSON.stringify(vehicleBrandCodeDeps);
    setValue('vehicle_model_code', '');
  }, vehicleBrandCodeDeps);

  const [skipModelCodeEffect, setSkipModelCodeEffect] = useState(false);
  const vehicleModelCodeEffectDependencies = useRef();
  const vehicleModelCodeDeps = [answers.vehicle_model_code];
  useEffect(() => {
    if (skipModelCodeEffect) {
      setSkipModelCodeEffect(false);
      return;
    }

    if (vehicleModelCodeEffectDependencies.current === undefined) {
      vehicleModelCodeEffectDependencies.current = JSON.stringify(vehicleModelCodeDeps);
      return;
    }

    if (
      vehicleModelCodeEffectDependencies.current
      === JSON.stringify(vehicleModelCodeDeps)
    ) {
      return;
    }

    vehicleModelCodeEffectDependencies.current = JSON.stringify(vehicleModelCodeDeps);

    setValue('vehicle_version_code', null);
    setValue('vehicle_am_code', '');
  }, vehicleModelCodeDeps);

  useEffect(() => {
    if (skipCheckInsuranceTypeRef.current === true) {
      skipCheckInsuranceTypeRef.current = false;
      return;
    }

    const triggerValidation = {
      shouldTouch: true,
      shouldDirty: true,
      shouldValidate: true,
    };

    if (answers.insurance_type === 'N') {
      setValue('first_insuranced_year', new Date().getFullYear().toString());
      setValue('violations', '0');
    } else if (answers.insurance_type === 'B') {
      setValue(
        'first_insuranced_year',
        answers.first_insuranced_year,
        triggerValidation,
      );
      setValue('violations', '0', triggerValidation);
    }
  }, [answers.insurance_type]);

  useEffect(() => {
    if (skipCheckGenderRef.current === true) {
      skipCheckGenderRef.current = false;
      return;
    }

    const triggerValidation = {
      shouldTouch: true,
      shouldDirty: true,
      shouldValidate: true,
    };

    if (answers.gender === 'G') {
      setValue('contractor_is_driver', '0');
      setValue('contractor_is_owner', '1');
      setValue('date_of_birth', null);
      setValue('country_of_birth_code', null);
      setValue('province_of_birth_code', null);
      setValue('commune_of_birth_code', null);
      setValue('born_abroad', false);
      setValue('civil_status_id', null);
      setValue('education_level_id', null);
      setValue('profession_id', null);
      setValue('driving_license_year', null);
      setValue('children', null);
      setValue('name', null);
      setValue('surname', null);
    } else if (
      ['M', 'F'].includes(answers.gender)
      && answers.date_of_birth === null
    ) {
      setValue('date_of_birth', '', triggerValidation);
      setValue('country_of_birth_code', 'Z000', triggerValidation);
      setValue('province_of_birth_code', null, triggerValidation);
      setValue('commune_of_birth_code', null, triggerValidation);
      setValue('born_abroad', false, triggerValidation);
      setValue('civil_status_id', '2', triggerValidation);
      setValue('education_level_id', '3', triggerValidation);
      setValue('profession_id', '1', triggerValidation);
      setValue('driving_license_year', '', triggerValidation);
      setValue('children', '0', triggerValidation);
      setValue('name', '', triggerValidation);
      setValue('surname', '', triggerValidation);
    }
  }, [answers.gender, answers.date_of_birth, answers.contractor_is_driver]);

  // Collect Owner Data
  const [owner, setOwner] = useState(
    StoredOwner || {
      isValid: false,
      name: '',
      surname: '',
      gender: '',
      fiscal_code: '',
      date_of_birth: '',
      country_of_birth_code: 'Z000',
      province_of_birth_code: null,
      commune_of_birth_code: null,
      born_abroad: false,
      residence_province_code: '',
      residence_commune_code: '',
      postal_code: '',
      address: '',
      house_number: '',
      civil_status_id: '2',
      education_level_id: '3',
      profession_id: '',
      valid_driving_license: '1',
      driving_license_year: '',
      business_name: '',
      vat_number: '',
      company_type: null,
    },
  );
  // Collect Driver Data
  const [driver, setDriver] = useState(
    StoredDriver || {
      isValid: false,
      name: '',
      surname: '',
      gender: '',
      fiscal_code: '',
      date_of_birth: '',
      country_of_birth_code: 'Z000',
      province_of_birth_code: null,
      commune_of_birth_code: null,
      born_abroad: false,
      residence_province_code: '',
      residence_commune_code: '',
      postal_code: '',
      address: '',
      house_number: '',
      civil_status_id: '2',
      education_level_id: '3',
      profession_id: '',
      valid_driving_license: '1',
      driving_license_year: '',
    },
  );
  const [bersani, setBersani] = useState(
    StoredBersani || {
      isValid: false,
      name: '',
      surname: '',
      date_of_birth: '',
      gender: '',
      commune_of_birth_code: '',
      vehicle_type: '',
      vehicle_plate: '',
    },
  );

  const getFiscalCode = (birthDate) => {
    const d = birthDate.split('-');
    const fData = {
      name: answers.name,
      surname: answers.surname,
      gender: answers.gender,
      day: d[2],
      month: d[1],
      year: d[0],
    };

    if (answers.born_abroad) {
      fData.birthplace = states.filter(
        (s) => s.state_code === answers.country_of_birth_code,
      )[0].name;
      fData.birthplaceProvincia = 'EE';
    } else {
      fData.birthplace = municipalities.filter(
        (municipality) => municipality.cadastral_code === answers.commune_of_birth_code,
      )[0].name;
      fData.birthplaceProvincia = answers.province_of_birth_code;
    }
    return generateFiscalCode(fData);
  };

  const getFiscalCodeOD = (data) => {
    const d = convertToItalianDate(data.date_of_birth).split('-');
    const fData = {
      name: data.name,
      surname: data.surname,
      gender: data.gender,
      day: d[2],
      month: d[1],
      year: d[0],
    };

    if (data.born_abroad) {
      fData.birthplace = states.filter(
        (s) => s.state_code === data.country_of_birth_code,
      )[0].name;
      fData.birthplaceProvincia = 'EE';
    } else {
      fData.birthplace = municipalities.filter(
        (municipality) => municipality.cadastral_code === data.commune_of_birth_code,
      )[0].name;
      fData.birthplaceProvincia = data.province_of_birth_code;
    }
    return generateFiscalCode(fData);
  };

  const submitAnswers = () => {
    const answersData = {
      ...answers,
      owner: answers.contractor_is_owner === '0' ? owner : null,
      driver: answers.contractor_is_driver === '0' ? driver : null,
      bersani:
        answers.insurance_type === 'N' && answers.inherit_merit_class != 'N'
          ? bersani
          : null,
    };

    answersData.date_of_birth = answers.date_of_birth !== null
      ? convertToItalianDate(answers.date_of_birth)
      : null;
    answersData.policy_effective_date = convertToItalianDate(
      answers.policy_effective_date,
    );

    answersData.fiscal_code = answers.gender !== 'G' ? getFiscalCode(answers.date_of_birth) : null;
    if (answers.contractor_is_owner === '0') {
      answersData.owner.date_of_birth = owner.gender !== 'G' ? convertToItalianDate(owner.date_of_birth) : null;
      answersData.owner.fiscal_code = owner.gender !== 'G' ? getFiscalCodeOD(owner) : null;
    }
    if (answers.contractor_is_driver === '0') {
      answersData.driver.date_of_birth = convertToItalianDate(
        driver.date_of_birth,
      );
      answersData.driver.fiscal_code = getFiscalCodeOD(driver);
    }

    autoSubmitAnswers(answersData, navigate);
  };

  const updateFormData = (answer, doNotRunVehicleInfoChangeChecks) => {
    if (doNotRunVehicleInfoChangeChecks !== true) {
      if (
        answer.vehicle_brand_code
        && answer.vehicle_brand_code != answers.vehicle_brand_code
      ) {
        answer.vehicle_model_code = '';
        answer.vehicle_version_code = null;
      } else if (
        (answer.vehicle_brand_code
          && answer.vehicle_brand_code != answers.vehicle_brand_code)
        || (answer.vehicle_model_code
          && answer.vehicle_model_code != answers.vehicle_model_code)
        || (answer.imm_vehicle_year
          && answer.imm_vehicle_year != answers.imm_vehicle_year)
      ) {
        answer.vehicle_version_code = null;
      }
    }

    updateAnswers({ ...answer });
  };

  const updateBersaniData = (data) => {
    setBersani({ ...bersani, ...data });
  };

  const updateOwnerFormData = (data) => {
    setOwner({ ...owner, ...data });
  };

  const updateDriverFormData = (data) => {
    setDriver({ ...driver, ...data });
  };

  const prevButton = () => {
    if (index > 1) {
      autoChangeIndex(index - 1);
    }
  };
  const validateDataPrivacy = () => {
    let { error, message } = '';

    dataPrivacy.forEach((privacy) => {
      if (privacy.required === 1) {
        const userDataArray = { ...answers }; // make a separate copy of the object
        const index = userDataArray.privacies.indexOf(privacy.id);

        if (index < 0) {
          error = true;
          message = 'Tutti i campi sono obbligatori! Tutta la privacy con la stella deve essere controllata';
        }
      }
    });
    return { error, message };
  };

  const nextButton = async () => {
    const res = await trigger(answersFieldsBySteps[index - 1], {
      shouldFocus: true,
    });

    if (res !== true) {
      return;
    }

    if (index === 2) {
      if (answers.contractor_is_driver === '0') {
        if (!driver.isValid) {
          driverFormComponentRef.current.triggerErrors();
          return;
        }
      }

      if (answers.contractor_is_owner === '0') {
        if (!owner.isValid) {
          ownerFormComponentRef.current.triggerErrors();
          return;
        }
      }

      if (answers.born_abroad && answers.country_of_birth_code === 'Z000') {
        window.scrollTo(0, 0);
        generalAlertErrorToast('Non puoi selezionare Italia come stato estero!');
        return;
      }

      if (
        answers.born_abroad
        && (answers.country_of_birth_code === null
          || answers.country_of_birth_code === ''
          || answers.country_of_birth_code == undefined)
      ) {
        window.scrollTo(0, 0);
        generalAlertErrorToast('Devi selezionare uno stato estero!');
        return;
      }
    }

    if (index === 3) {
      if (
        answers.inherit_merit_class === 'S'
        || (answers.inherit_merit_class == 'A' && answers.insurance_type === 'N')
      ) {
        if (!bersani.isValid) {
          bersaniComponentRef.current.triggerErrors();
          return;
        }
      }
    }

    if (index === 4) {
      const validation = validateDataPrivacy();
      if (validation.error) {
        generalAlertError(validation.message);
        return;
      }
    }

    if (index === 4) {
      submitAnswers();
      return;
    }

    autoChangeIndex(index + 1);
    setTimeout(() => window.scrollTo(0, 0), 500);
  };

  let typeGuide;

  if (answers.other_drivers === '1') {
    typeGuide = guideType.filter((guide) => {
      if (
        answers.youngest_age_driver < 26
        || answers.youngest_age_family_member < 26
      ) {
        return guide.id === 'free';
      }
      return guide.id !== 'exclusive';
    });
  } else {
    typeGuide = guideType.filter((guide) => {
      if (answers.youngest_age_family_member < 26) {
        return guide.id !== 'expert';
      }
      if (answers.youngest_age_family_member >= 26) {
        return guide;
      }

      return false;
    });
  }

  if (answers.gender === 'G') {
    typeGuide = guideType.filter((guide) => guide.id === 'free');
  }
  // const getVehicleInfo = async () => {
  //   autoGetVehicleInformation(answers.vehicle_plate, updateFormData);
  // };

  // useEffect(() => {
  //   if (location.state === null && validateCarPlate(answers.vehicle_plate) && (answers.renew_check || answers.new_risks_check)) {
  //     confirmAlertFastQuote('Utilizzeremo le banche dati per trovare i dati del cliente.', () => autoGetVehicleInformation(answers.vehicle_plate, updateFormData), () => updateFormData({
  //       renew_check: false,
  //       new_risks_check: false,
  //     }));
  //   }
  // }, [answers.renew_check, answers.new_risks_check]);

  const getQuickInfo = () => {
    autoGetQuickInfo(answers.vehicle_plate, updateFormData);
  };

  const procceedWithFastQuote = () => {
    if (location.state === null && validateCarPlate(answers.vehicle_plate)) {
      if (answers.renew_check === false && answers.new_risks_check === false) {
        generalAlertError(
          'Seleziona Rinnovo o Nuovo Rischio Per Continuare',
          'Aviso',
        );
        return;
      }
      autoGetVehicleInformation(answers.vehicle_plate, getQuickInfo);
      setProceedButton(false);
      // confirmAlertFastQuote('Utilizzeremo le banche dati per trovare i dati del cliente.', () => autoGetVehicleInformation(answers.vehicle_plate, updateFormData), () => updateFormData({
      //   renew_check: false,
      //   new_risks_check: false,
      // }));
    }
  };

  useEffect(() => {
    if (answers.insurance_type === 'N' && answers.inherit_merit_class === 'N') {
      updateFormData({
        merit_class: '14',
      });
    }
  }, [answers.insurance_type, answers.inherit_merit_class]);

  const getVehicleInfoWithSpeechToText = async (targa) => {
    autoGetVehicleInformationWithSpeechToText(targa);
  };

  const searchActiveClient = (searchClient) => async () => {
    if (searchClient.length === 0) return;
    autoSearchActiveClient(searchClient);
  };

  const cancelFastquote = () => {
    autoCancelFastQuote();
  };

  useDebouncedEffect(searchActiveClient(searchClient), [searchClient], 400);

  useEffect(() => {
    autoGetInitialData();

    return () => {
      resetAutoForm();
    };
  }, []);

  const confirmFastQuote = () => {
    if (answers.renew_check === true) {
      if (fastQuoteVehicleInfo.frontend.persona_giuridica === true) {
        const commune_of_birth_code = null;
        const province_of_birth_code = null;
        const born_abroad = false;
        const country_of_birth_code = null;

        setSkipBrandCodeEffect(true);
        setSkipModelCodeEffect(true);
        skipCheckGenderRef.current = true;
        updateFormData(
          {
            ...fastQuoteVehicleInfo.frontend,
            policy_effective_date:
              getAllowedPolicyEffectDate('min')
                <= fastQuoteVehicleInfo.frontend.policy_effective_date
                && fastQuoteVehicleInfo.frontend.policy_effective_date
                <= getAllowedPolicyEffectDate('max')
                ? fastQuoteVehicleInfo.frontend.policy_effective_date
                : '',
            date_of_birth: null,
            commune_of_birth_code,
            province_of_birth_code,
            born_abroad,
            country_of_birth_code,
            gender: 'G',

            contractor_is_driver: '0',
            civil_status_id: null,
            education_level_id: null,
            profession_id: null,
            driving_license_year: null,
            children: null,
          },
          true,
        );
      } else {
        const has_fiscal_code = fastQuoteVehicleInfo.frontend.fiscal_code !== '-';
        let fiscalObj = null;
        let commune_of_birth_code = null;
        let province_of_birth_code = null;
        let born_abroad = false;
        let country_of_birth_code = 'Z000';
        let gender = null;
        let date_of_birth = null;

        if (has_fiscal_code) {
          fiscalObj = decodeFiscalCode(fastQuoteVehicleInfo.frontend.fiscal_code);

          const commune = municipalities.find(
            (municipality) => municipality.name.toLowerCase() === fiscalObj.birthplace.toLowerCase(),
          );

          if (fiscalObj.birthplaceProvincia !== 'EE') {
            if (commune) {
              commune_of_birth_code = commune.cadastral_code;
              province_of_birth_code = fiscalObj.birthplaceProvincia;
            }
          } else {
            born_abroad = true;
            const state = states.find((s) => s.name === (fiscalObj.birthplace === 'MACEDONIA DEL NORD' ? 'MACEDONIA' : fiscalObj.birthplace));
            if (state !== undefined) {
              country_of_birth_code = state.state_code;
            }
          }

          gender = fiscalObj.gender;
          date_of_birth = `${fiscalObj.year}-${fiscalObj.month < 10 ? `0${fiscalObj.month}` : fiscalObj.month}-${fiscalObj.day < 10 ? `0${fiscalObj.day}` : fiscalObj.day}`;
        }

        setSkipBrandCodeEffect(true);
        setSkipModelCodeEffect(true);
        skipCheckGenderRef.current = true;
        updateFormData(
          {
            ...fastQuoteVehicleInfo.frontend,
            policy_effective_date:
              getAllowedPolicyEffectDate('min')
                <= fastQuoteVehicleInfo.frontend.policy_effective_date
                && fastQuoteVehicleInfo.frontend.policy_effective_date
                <= getAllowedPolicyEffectDate('max')
                ? fastQuoteVehicleInfo.frontend.policy_effective_date
                : '',
            date_of_birth,
            commune_of_birth_code,
            province_of_birth_code,
            born_abroad,
            country_of_birth_code,
            gender,
          },
          true,
        );
      }
    } else if (answers.new_risks_check === true) {
      setSkipBrandCodeEffect(true);
      setSkipModelCodeEffect(true);
      const {
        vehicle_model_code,
        vehicle_brand_code,
        violations,
        violations_data,
        violations_number,
        imm_vehicle_year,
        imm_vehicle_month,
        power,
        weight,
        first_insuranced_year,
      } = fastQuoteVehicleInfo.frontend;
      updateFormData(
        {
          vehicle_model_code,
          vehicle_brand_code,
          violations,
          violations_data,
          violations_number,
          imm_vehicle_year,
          imm_vehicle_month,
          power,
          weight,
          first_insuranced_year,
        },
        true,
      );
    }

    autoCancelFastQuote();
  };

  const closeClientModal = () => {
    setSearchClient('');
    autoUpdateFormData({
      selectedClient: null,
      selectedClientOldData: null,
      selectedClientId: null,
      searchResults: [],
    });
  };

  const confirmActiveClient = async (selectedClient) => {
    if (
      JSON.stringify(selectedClientOldData) !== JSON.stringify(selectedClient)
    ) {
      await updateActiveCustomer(selectedClientId, {
        ...selectedClient,
        date_of_birth:
          selectedClient.gender !== 'G'
            ? convertToItalianDate(selectedClient.date_of_birth)
            : null,
      });
    }

    if (selectedClient.gender === 'G') {
      updateFormData({
        born_abroad: false,
        civil_status_id: null,
        education_level_id: null,
        fiscal_code: null,
        gender: selectedClient.gender,
        house_number: selectedClient.house_number,
        name: null,
        phone: selectedClient.phone,
        postal_code: selectedClient.postal_code,
        profession_id: null,
        surname: null,
        address: selectedClient.address,
        date_of_birth: null,
        email: selectedClient.email,
        province_of_birth_code: null,
        residence_commune_code: selectedClient.residence_city_code, // residence_city_code
        residence_province_code: selectedClient.residence_province_code,
        country_of_birth_code: null,
        commune_of_birth_code: null, // city_of_birth_code,
        business_name: selectedClient.business_name,
        vat_number: selectedClient.vat_number,
        company_type: selectedClient.company_type,
        active_customer_id: selectedClientId,
      });
    } else {
      updateFormData({
        born_abroad: selectedClient.born_abroad,
        civil_status_id: selectedClient.civil_status_id.toString(),
        education_level_id: selectedClient.education_level_id.toString(),
        fiscal_code: selectedClient.fiscal_code,
        gender: selectedClient.gender,
        house_number: selectedClient.house_number,
        name: selectedClient.name,
        phone: selectedClient.phone,
        postal_code: selectedClient.postal_code,
        profession_id: selectedClient.profession_id.toString(),
        surname: selectedClient.surname,
        address: selectedClient.address,
        date_of_birth: selectedClient.date_of_birth,
        email: selectedClient.email,
        province_of_birth_code: selectedClient.province_of_birth_code,
        residence_commune_code: selectedClient.residence_city_code, // residence_city_code
        residence_province_code: selectedClient.residence_province_code,
        country_of_birth_code: selectedClient.country_of_birth_code,
        commune_of_birth_code: selectedClient.city_of_birth_code, // city_of_birth_code,
        business_name: null,
        vat_number: null,
        company_type: null,
        active_customer_id: selectedClientId,
      });
    }

    setSearchClient('');
    autoUpdateFormData({
      selectedClient: null,
      selectedClientOldData: null,
      selectedClientId: null,
      searchResults: [],
    });
  };

  const onSearchChange = (value) => {
    setSearchClient(value);

    if (value.length === 0) {
      autoUpdateFormData({
        searchResults: [],
      });
    }
  };

  const getCustomerData = async (customerId) => {
    autoGetCustomerData(customerId);
  };

  const setDriverForm = (value) => {
    autoUpdateFormData({
      driverForm: value,
    });
  };

  const setOwnerForm = (value) => {
    autoUpdateFormData({
      ownerForm: value,
    });
  };

  useEffect(() => {
    Storage.setVehicleQuoteAnswers(answers);
    Storage.setVehicleOwnerData(owner);
    Storage.setVehicleDriverData(driver);
    Storage.setBersani(bersani);
  }, [answers, owner, driver, bersani]);

  useEffect(() => {
    if (answers.vehicle_brand_code) {
      autoGetVehicleModels(answers.vehicle_brand_code, answers.imm_vehicle_year);
    }
  }, [answers.vehicle_brand_code, answers.imm_vehicle_year]);

  useEffect(() => {
    if (
      answers.vehicle_brand_code
      && answers.vehicle_model_code
      && answers.imm_vehicle_year
    ) {
      autoGetVehicleVersions(
        answers.vehicle_model_code,
        answers.imm_vehicle_year,
      );
    }
  }, [
    answers.vehicle_brand_code,
    answers.vehicle_model_code,
    answers.imm_vehicle_year,
  ]);

  const privacyItemChangeHandler = (value, item) => {
    if (!value.target.checked) {
      const newArray = [...answers.privacies];
      const index = newArray.indexOf(item.id);
      newArray.splice(index, 1);
      updateAnswers({
        privacies: newArray,
      });
      return;
    }
    const answersNewArray = [...answers.privacies];
    answersNewArray.push(item.id);
    updateAnswers({
      privacies: answersNewArray,
    });
  };

  useEffect(() => {
    const callAsync = async () => {
      if (
        location.state !== null
        && location.state.requestToken !== undefined
      ) {
        skipCheckGenderRef.current = true;
        const {
          data: {
            data: {
              answers, owner, driver, bersani,
            },
          },
        } = await modifyRequestData(location.state.requestToken);

        setSkipBrandCodeEffect(true);
        setSkipModelCodeEffect(true);
        setSkipQuickInfo(true);
        updateAnswers(answers);
        setOwner(owner);
        setDriver(driver);
        setBersani(bersani);
      }
    };

    callAsync();
  }, []);

  fastQuoteInfoRef.current = fastQuoteVehicleInfo;
  confirmFastQuoteRef.current = confirmFastQuote;

  useEffect(() => {
    if (Storage.getTranscription() !== null) {
      if (Storage.getTranscription().includes('targa')) {
        const newStr = Storage.getTranscription().split('targa');

        const targa = newStr[1].split(' ').join('');

        updateFormData({
          vehicle_plate: targa.toUpperCase(),
          renew_check: true,
        });

        Storage.removeTranscription();

        setTimeout(() => {
          if (
            (answers.renew_check === true && answers.vehicle_plate !== '')
            || answers.vehicle_plate !== undefined
          ) {
            getVehicleInfoWithSpeechToText(targa.toUpperCase());
          }
        }, 2000);

        const expDate = moment().add(2, 'minutes').toDate().getTime();
        let interval = null;

        const clearExpiredInterval = () => {
          clearInterval(interval);
        };

        interval = setInterval(() => {
          const currDate = new Date().getTime();
          if (currDate > expDate) {
            clearExpiredInterval();
            return;
          }
          if (fastQuoteInfoRef.current !== null) {
            clearExpiredInterval();
            confirmFastQuoteRef.current();
          }
        }, 2000);
      }
    }
  }, []);

  useEffect(() => {
    if (location.state !== null) {
      if (location.state.activeCustomerId !== undefined) {
        getCustomerData(location.state.activeCustomerId);
      }

      if (location.state.type === 'command') {
        const vehiclePlate = location.state.data.vehicle_plate.toUpperCase();

        updateFormData({
          vehicle_plate: vehiclePlate,
          renew_check: true,
        });

        // setTimeout(() => {
        //   if (
        //     (answers.renew_check === true && answers.vehicle_plate !== '')
        //     || answers.vehicle_plate !== undefined
        //   ) {
        //     autoGetVehicleInformation(vehiclePlate);
        //   }
        // }, 2000);

        const expDate = moment().add(2, 'minutes').toDate().getTime();
        let interval = null;

        const clearExpiredInterval = () => {
          clearInterval(interval);
        };

        interval = setInterval(() => {
          const currDate = new Date().getTime();
          if (currDate > expDate) {
            clearExpiredInterval();
            return;
          }
          if (fastQuoteInfoRef.current !== null) {
            clearExpiredInterval();
            confirmFastQuoteRef.current();
          }
        }, 2000);
      }
    }
  }, []);

  useEffect(() => {
    if (!answers.driving_license_year) {
      if (answers.date_of_birth) {
        const birthYear = answers.date_of_birth.split('-')[0];
        const regex = /^\d{4}$/;
        if (regex.test(parseInt(birthYear))) {
          const drivingLicenseYear = parseInt(birthYear) + 18;
          updateAnswers({
            driving_license_year: drivingLicenseYear.toString(),
          });
        }
      }
    }
  }, [answers.date_of_birth]);

  useEffect(() => {
    const run = async () => {
      const isValidVehiclePlate = (await isValidPlate(answers.vehicle_plate))
        && answers.vehicle_plate.length === 7;

      if (
        skipQuickInfo === false
        && isValidVehiclePlate
        && answers.complete_manually === true
      ) {
        setSkipBrandCodeEffect(true);
        setSkipModelCodeEffect(true);
        autoGetQuickInfo(answers.vehicle_plate, updateFormData);
      } else if (skipQuickInfo === true) {
        setSkipQuickInfo(false);
      }
    };
    run();
  }, [answers.vehicle_plate, answers.complete_manually]);

  useEffect(() => {
    if (answers.imm_vehicle_year !== '') {
      setProceedButton(false);
    }
  }, [answers.imm_vehicle_year]);

  const checkBoxTypeRef = useRef(null);

  // const [{
  //   run, stepIndex, joySteps,
  // }, setState] = useSetState({
  //   run: false,
  //   stepIndex: 0,
  //   joySteps: [],
  // });

  // useEffect(() => {
  //   if (index === 1 && localStorage.getItem('vehicleTourStepOne') !== 'done') {
  //     mountJoyrideStepsIndexOne(answers, setState, null);
  //   }
  //   if (index === 2 && localStorage.getItem('vehicleTourStepTwo') !== 'done') {
  //     mountJoyrideStepsIndexTwo(setState);
  //   }
  //   if (index === 3 && localStorage.getItem('vehicleTourStepThree') !== 'done') {
  //     mountJoyrideStepsIndexThree(setState);
  //   }
  //   if (index === 4 && localStorage.getItem('vehicleTourStepFour') !== 'done') {
  //     mountJoyrideStepsIndexFour(setState);
  //   }
  // }, [answers.vehicle_plate, answers.renew_check, answers.complete_manually, answers.new_risks_check, answers.vehicle_brand_code, index, localStorage.getItem('vehicleTourStepOne'), localStorage.getItem('vehicleTourStepTwo'), localStorage.getItem('vehicleTourStepThree'), localStorage.getItem('vehicleTourStepFour')]);

  // const fireVehicleStepOneTour = () => {
  //   mountJoyrideStepsIndexOne(answers, setState, 0);
  // };

  // const fireVehicleStepTwoTour = () => {
  //   localStorage.removeItem('vehicleTourStepTwo');
  //   setState({ run: true });
  // };

  // const fireVehicleStepThreeTour = () => {
  //   localStorage.removeItem('vehicleTourStepThree');
  //   setState({ run: true });
  // };
  // const fireVehicleStepFourTour = () => {
  //   localStorage.removeItem('vehicleTourStepFour');
  //   setState({ run: true });
  // };

  useEffect(() => {
    if (fastQuoteId && fastQuoteData === null) {
      getFastQuoteData(fastQuoteId).then((fast_quote_response) => {
        setFastQuoteData(fast_quote_response.data);
      }).catch((error) => {
        console.error('Error fetching fast quote data: ', error);
      });
    }

    if (fastQuoteData && fastQuoteData.vehicle_plate !== null) {
      updateFormData({
        vehicle_plate: fastQuoteData.vehicle_plate.toUpperCase(),
        renew_check: true,
        insurance_type: 'B',
        new_risks_check: false,
        complete_manually: false,
      });
      procceedWithFastQuote();
    }
  }, [fastQuoteId, fastQuoteData]);

  if (loading) {
    return <PageLoading />;
  }

  if (loadingQuotes) {
    return <QuoteGenerateLoading countNr={80} />;
  }

  return (
    <>
      {/* <Joyride
        callback={(data) => handleCallbackRcVehicle(data, setState, index)}
        continuous
        run={run}
        scrollToFirstStep
        showProgress
        showSkipButton
        stepIndex={stepIndex}
        steps={joySteps}
        styles={{
          options: joyrideOptions,
          buttonNext: {
            backgroundColor: '#00a651',
            outline: 'none',
          },
          buttonBack: {
            color: '#00a651',
            outline: 'none',
          },
          beaconInner: {
            backgroundColor: '#00a651',
          },
          beaconOuter: {
            borderColor: '#00a651',
            backgroundColor: 'rgba(68 , 154 , 71, 0.4)',
          },
          buttonSkip: {
            outline: 'none',
          },
          buttonClose: {
            outline: 'none',
          },
        }}
        locale={{
          back: 'Indietro',
          close: 'Chiudi',
          last: 'Fine',
          next: 'Prossima',
          open: 'Apri il modale',
          restart: 'Riavvia il tour ',
          skip: 'Salta',
        }}
      /> */}

      {fastQuoteVehicleInfo !== null && (
        <RcAutoFastQuoteModal
          cancelFastquote={cancelFastquote}
          confirmFastQuote={confirmFastQuote}
          answers={answers}
          fastQuoteVehicleInfo={fastQuoteVehicleInfo}
        />
      )}
      <div className="d-flex align-content-center justify-content-center">
        <ProgressBar activeStep={index} steps={stepsQuote} />
      </div>
      <div>
        {isSubmitting && (
          <div className="stopInteraction">
            <div className="black-cover" />
            <span>
              <div className="text-center">
                <img
                  className="loading-gif"
                  src={LoadingGif}
                  alt=":aoding indicator"
                />
                <br />
                Attendere prego
              </div>
            </span>
          </div>
        )}

        <CenteredDivTitle className="d-flex align-items-center justify-content-center gap-3 mt-3">
          <div className="row title-questionnaire">
            <div className="quote-h2 divider">
              <h2>{title}</h2>
            </div>
          </div>
        </CenteredDivTitle>
        {/* {index === 1 && (
          <div>
            {localStorage.getItem('vehicleTourStepOne') === 'done' && (
              <div style={{ cursor: 'pointer', top: '8%' }} onClick={() => fireVehicleStepOneTour()} className="position-fixed end-0 mt-3 me-3">
                <button className="btn btn-success ">
                  <FontAwesomeIcon icon={faBook} size="lg" />
                </button>
              </div>
            )}
          </div>
        )}
        {index === 2 && (
          <div>
            {localStorage.getItem('vehicleTourStepTwo') === 'done' && (
              <div style={{ cursor: 'pointer', top: '8%' }} onClick={() => fireVehicleStepTwoTour()} className="position-fixed end-0 mt-3 me-3">
                <button className="btn btn-success px-2 py-1">Tutorial</button>
              </div>
            )}
          </div>
        )}
        {index === 3 && (
          <div>
            {localStorage.getItem('vehicleTourStepThree') === 'done' && (
              <div style={{ cursor: 'pointer', top: '8%' }} onClick={() => fireVehicleStepThreeTour()} className="position-fixed end-0 mt-3 me-3">
                <button className="btn btn-success px-2 py-1">Tutorial</button>
              </div>
            )}
          </div>
        )}
        {index === 4 && (
          <div>
            {localStorage.getItem('vehicleTourStepFour') === 'done' && (
              <div style={{ cursor: 'pointer', top: '8%' }} onClick={() => fireVehicleStepFourTour()} className="position-fixed end-0 mt-3 me-3">
                <button className="btn btn-success px-2 py-1">Tutorial</button>
              </div>
            )}
          </div>
        )} */}

        {index === 1 && (
          <RcAutoStepOne
            errors={errors}
            updateFormData={updateFormData}
            answers={answers}
            isValid={isValid}
            checkBoxTypeRef={checkBoxTypeRef}
            isLoadingFastQuote={isLoadingFastQuote}
            register={register}
            brands={brands}
            isLoadingVehicleModels={isLoadingVehicleModels}
            vehicleModels={vehicleModels}
            isLoadingVehicleVersions={isLoadingVehicleVersions}
            vehicleVersions={vehicleVersions}
            formData={formData}
          />
        )}
        {index === 2 && (
          <RcAutoStepTwo
            register={register}
            answers={answers}
            errors={errors}
            searchClient={searchClient}
            onSearchChange={onSearchChange}
            searchResults={searchResults}
            getCustomerData={getCustomerData}
            closeClientModal={closeClientModal}
            selectedClient={selectedClient}
            confirmActiveClient={confirmActiveClient}
            municipalities={municipalities}
            states={states}
            formData={formData}
            isValid={isValid}
            updateFormData={updateFormData}
            driverFormComponentRef={driverFormComponentRef}
            driverForm={driverForm}
            setDriverForm={setDriverForm}
            driver={driver}
            updateDriverFormData={updateDriverFormData}
            validateItalianPostCode={validateItalianPostCode}
            ownerFormComponentRef={ownerFormComponentRef}
            ownerForm={ownerForm}
            setOwnerForm={setOwnerForm}
            owner={owner}
            updateOwnerFormData={updateOwnerFormData}
          />
        )}
        {index === 3 && (
          <RcAutoStepThree
            answers={answers}
            register={register}
            isValid={isValid}
            errors={errors}
            updateFormData={updateFormData}
            bersaniComponentRef={bersaniComponentRef}
            bersani={bersani}
            updateBersaniData={updateBersaniData}
            municipalities={municipalities}
            typeGuide={typeGuide}
          />
        )}
        {index === 4 && (
          <RcAutoStepFour
            answers={answers}
            isValid={isValid}
            register={register}
            errors={errors}
            updateAnswers={updateAnswers}
            dataPrivacy={dataPrivacy}
            privacyItemChangeHandler={privacyItemChangeHandler}
          />
        )}
      </div>

      {index == 1 && proceedButton === true && (
        <div className="d-flex justify-content-center align-items-center mt-5">
          <button
            className="btn btn-questionnaire "
            onClick={() => procceedWithFastQuote()}
          >
            Procedi
          </button>
        </div>
      )}

      {index >= 1 && index <= 4 && (
        <div className="footer-buttons">
          <div className="d-flex justify-content-between">
            {index !== 1 && (
              <button
                className="btn btn-questionnaire back backtoStepOne backtoStepTwo backtoStepThree"
                onClick={prevButton}
              >
                Indietro
              </button>
            )}
            {proceedButton === false && (
              <button
                className="btn btn-questionnaire continueStepThree continueStepFour buttonFinal"
                onClick={nextButton}
                id="continueStep2"
              >
                {index === 4 ? 'Vai ai preventivi' : 'Continua'}
              </button>
            )}
          </div>
        </div>
      )}
    </>
  );
}

export default function () {
  const { loading, availableProducts } = useSelector(
    (store) => store.auth.user_products,
  );

  useEffect(() => {
    getAvailableProducts();
  }, []);

  if (loading === false && !availableProducts.includes(1)) {
    return <Navigate to="/quote" />;
  }

  return <RcVehicle />;
}
