import React, { useEffect, useState } from 'react';
import AsyncSelect from 'react-select/async';
import { FormInputCheckbox } from './FormInputCheckbox';
import { Label, SelectContainer } from './styles/common';

function FormReactSelectInputBirthPlace({
  options, states, values, onChange, valid, error, label, name, valid_country_of_birth_code, error_country_of_birth_code,
}) {
  const [perpOptions, setPerpOptions] = useState([]);
  const [prepStates, setPrepStates] = useState([]);
  const [inputText, setInputText] = useState('');
  const defaultBorder = '#CBCBCD';
  const filterMunicipalities = (inputValue) => {
    if (inputValue.length >= 3) {
      return perpOptions.filter((i) => i.label.toLowerCase().startsWith(inputValue.toLowerCase()));
    }

    return [];
  };

  const promiseOptions = (inputValue) => new Promise((resolve) => {
    setTimeout(() => {
      resolve(filterMunicipalities(inputValue));
    }, 500);
  });

  const filterStates = (inputValue) => {
    if (inputValue.length >= 3) {
      return prepStates.filter((i) => i.label.toLowerCase().startsWith(inputValue.toLowerCase()));
    }

    return [];
  };

  const promiseOptionsStates = (inputValue) => new Promise((resolve) => {
    setTimeout(() => {
      resolve(filterStates(inputValue));
    }, 500);
  });

  const handleChange = (value) => {
    if (value.born_abroad !== undefined) {
      if (value.born_abroad) {
        onChange({
          born_abroad: value.born_abroad,
          commune_of_birth_code: null,
          province_of_birth_code: null,
        });
      } else {
        onChange({
          born_abroad: value.born_abroad,
          country_of_birth_code: 'Z000',
          province_of_birth_code: null,
          commune_of_birth_code: null,
        });
      }
      return;
    }

    if (!value.born_abroad) {
      if (value.commune_of_birth_code !== undefined || value.commune_of_birth_code !== '') {
        const commune = options.find((opt) => opt.cadastral_code === value.commune_of_birth_code);

        if (commune !== undefined) {
          onChange({
            commune_of_birth_code: commune.cadastral_code,
            province_of_birth_code: commune.communal_territory.car_plate_symbol,
            born_abroad: false,
            country_of_birth_code: 'Z000',
          });
          return;
        }
      }
    }

    if (value.commune_of_birth_code === null || value.commune_of_birth_code === '') {
      onChange({
        commune_of_birth_code: '',
        province_of_birth_code: '',
        born_abroad: false,
        country_of_birth_code: 'Z000',
      });
      return;
    }

    if (value.country_of_birth_code !== null || value.country_of_birth_code !== '' || value.country_of_birth_code !== undefined) {
      onChange({
        country_of_birth_code: value.country_of_birth_code,
        commune_of_birth_code: null,
        province_of_birth_code: null,
        born_abroad: true,
      });
    }
  };

  const handleInputChange = (inputValue) => {
    setInputText(inputValue);
  };

  const handleMenuClose = () => {
    if (!values.commune_of_birth_code && inputText) {
      const startingWithOptions = perpOptions.filter((option) => option.label.toLowerCase().startsWith(inputText.toLowerCase()));
      const firstStartingOption = startingWithOptions[0];
      const findCarPlateSymbol = options.find((opt) => opt.cadastral_code === firstStartingOption.value);
      if (firstStartingOption) {
        onChange({
          commune_of_birth_code: firstStartingOption.value,
          province_of_birth_code: findCarPlateSymbol.communal_territory.car_plate_symbol,
          born_abroad: false,
          country_of_birth_code: 'Z000',
        });
      }
    }
  };

  useEffect(() => {
    setPerpOptions(options.map((i) => ({ value: i.cadastral_code, label: `${i.name} (${i.communal_territory.car_plate_symbol})` })));
    setPrepStates(states.map((i) => ({ value: i.state_code, label: i.name })));
  }, [options]);

  return (
    <div className="form-input-container">
      <div className="row">
        <div className="col-12 col-md-12">
          <Label>{label}</Label>
          <SelectContainer>
            <AsyncSelect
              onMenuClose={handleMenuClose}
              onInputChange={handleInputChange}
              loadOptions={promiseOptions}
              defaultOptions
              isSearchable
              placeholder=""
              name={name}
              isDisabled={values.born_abroad === true}
              value={values.born_abroad === false ? options.filter((opt) => opt.cadastral_code === values.commune_of_birth_code).map((o) => ({ value: o.cadastral_code, label: `${o.name} (${o.communal_territory.car_plate_symbol})` }))[0] : ''}
              onChange={(val) => {
                handleChange({
                  commune_of_birth_code: val === null || val === undefined ? '' : val.value,
                });
              }}
              loadingMessage={() => 'Caricando communi...'}
              clearValue
              isClearable
              noOptionsMessage={() => 'Digita almeno tre lettere del comune!'}
              styles={{
                control: (baseStyles, state) => ({
                  ...baseStyles,
                  borderWidth: '2px !important',
                  borderColor: `${error ? '#DC3545' : valid ? '#198754' : state.isSeleced ? defaultBorder : state.isFocused ? defaultBorder : defaultBorder}`,
                  padding: '0rem 0.75rem',
                  fontWeight: 'bold',
                  borderRadius: '0.375rem',
                  backgroundColor: '#FBFAFA',
                  '&:hover': defaultBorder,
                  boxShadow: 'none',
                }),
              }}
            />
          </SelectContainer>
          <FormInputCheckbox
            label="Stato estero"
            name="born_abroad"
            checked={values.born_abroad}
            onChange={() => {
              handleChange({ born_abroad: !values.born_abroad });
            }}
          />
          {values.born_abroad === true && states !== undefined
            && (
              <AsyncSelect
                loadOptions={promiseOptionsStates}
                defaultOptions
                isSearchable
                name="country_of_birth_code"
                onChange={(val) => handleChange({
                  country_of_birth_code: val !== null ? val.value : '',
                })}
                value={states.filter((opt) => opt.state_code === values.country_of_birth_code).map((o) => ({ value: o.state_code, label: o.name }))[0]}
                styles={{
                  control: (baseStyles, state) => ({
                    ...baseStyles,
                    borderWidth: '2px !important',
                    borderColor: `${(error_country_of_birth_code || !valid_country_of_birth_code) ? '#DC3545' : valid ? '#198754' : state.isSeleced ? defaultBorder : state.isFocused ? defaultBorder : defaultBorder}`,
                    padding: '0.2rem 0.75rem',
                    fontWeight: 'bold',
                    borderRadius: '0.375rem',
                    backgroundColor: '#FBFAFA',
                    '&:hover': defaultBorder,
                    boxShadow: 'none',
                    textAlign: 'center',
                  }),
                }}
                className="mt-3"
              />
            )}
        </div>
      </div>
      {error
      && (
      <div className="text-center general-input-error">
        {error}
        {' '}
        <br />
        {error_country_of_birth_code}
      </div>
      )}
    </div>
  );
}

export default FormReactSelectInputBirthPlace;
