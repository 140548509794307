import React from 'react';
import moment from 'moment';
import {
  InfoBoxContainer, InfoItem, SanitariaCardInfoStyles, TitleActionBox,
} from './SanitariaStranieriInfoCard.styles';
import { ModificaDatiButton } from '../common/Redesign.styles';
import editIcon from '../../../../assets/redesign_icons/edit-icon.svg';

function SanitariaStranieriInfoCard({ customer, insuranceRequest, modifyData }) {
  return (
    <SanitariaCardInfoStyles>
      <TitleActionBox>
        <h4>
          Informazioni
          {' '}
          <b>Sanitaria Per Stranieri</b>
        </h4>
        {modifyData && (
        <ModificaDatiButton onClick={() => modifyData()}>
          <p>Modifica Dati</p>
          <img src={editIcon} alt="Modifica Dati" />
        </ModificaDatiButton>
        )}
      </TitleActionBox>
      <InfoBoxContainer>
        <InfoItem>
          <h4>Contraente:</h4>
          <p>
            {customer.name}
            {' '}
            {customer.surname}
          </p>
        </InfoItem>
        <InfoItem>
          <h4>Provenienza:</h4>
          <p>
            {insuranceRequest.sanitaria_stranieri.europe_world === 'europe' ? 'Europe' : 'Mondo'}

          </p>
        </InfoItem>

        <InfoItem>
          <h4>Durata in giorno:</h4>
          <p>
            {insuranceRequest.sanitaria_stranieri.days_of_staying}

          </p>
        </InfoItem>
        <InfoItem>
          <h4>Inizio Copertura:</h4>
          <p>
            {moment(insuranceRequest.policy_effective_date).format('DD-MM-YYYY')}
          </p>
        </InfoItem>
      </InfoBoxContainer>
    </SanitariaCardInfoStyles>
  );
}

export default SanitariaStranieriInfoCard;
