import React, { useEffect, useRef, useState } from 'react';
import moment from 'moment';
import { useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import { useSelector } from 'react-redux';
import { useLocation, useNavigate } from 'react-router-dom';
import { SanitariaFormFlowStyles, SanitariaFormStyles } from './styles/SanitariaPerStranieriFormFlow.styles';
import { CenteredDivTitle } from '../../common/components/CenteredPageTitle.styles';
import CenteredPageTitle from '../../common/components/CenteredPageTitle';
import { generalAlertError, generalAlertErrorToast } from '../../common/utils/alerts';
import {
  resetSanitariaStranieriForm, saniariaStranieriGetInitialData, sanitariaStranieriGetCustomerData,
  sanitariaStranieriSubmitAnswers,
} from '../../features/forms/formsActions';
import generateFiscalCode from '../../common/helpers/generateFiscalCode';
import { FormInputRadio } from '../../common/formInputs/FormInputRadio';
import { europe_or_world, genders } from '../../common/constants';
import { FormInputText } from '../../common/formInputs/FormInputText';
import { FormInputDate } from '../../common/formInputs/FormInputDate';
import getMinAllowedBirthday from '../../common/helpers/getMinAllowedBirthday';
import FormReactSelectInputBirthPlace from '../../common/formInputs/FormReactSelectInputBirthPlace';
import { FormInputAddressCompleteManual } from '../../common/formInputs/FormInputAddressCompleteManual';
import { FormInputAddressComplete } from '../../common/formInputs/FormInputAddressComplete';
import { FormInputSelect } from '../../common/formInputs/FormInputSelect';
import { greeniaPrivacyPolicy, modifyRequestData } from '../../services/axios-client/axeCommons';
import InputPrivacy from '../../common/components/inputs/InputPrivacy';
import { sanitariaSchema } from './SanitariaPerStranieriFormFlow.validations';
import { FormContainer } from '../../common/components/elements/OffcanvasForm.styles';
import { FormInputNumber } from '../../common/formInputs/FormInputNumber';
import QuoteGenerateLoading from '../../common/components/elements/QuoteGenerateLoading';

function SanitariaPerStranieriFormFlow() {
  const navigate = useNavigate();
  const location = useLocation();
  //   const [showTooltip, setShowTooltip] = useState(false);
  const skipCheckGenderRef = useRef(null);
  const state = useSelector((store) => store.forms.sanitaria_stranieri);
  const [showManualAdress, setShowManualAdress] = useState(false);
  const {
    loading,
    states,
    municipalities,
    formData,
    dataPrivacy,

  } = state;
  const {
    handleSubmit,
    register,
    formState: { errors, touchedFields },
    setValue,
    trigger,
    getValues,
    watch,
  } = useForm({
    mode: 'all',
    resolver: yupResolver(sanitariaSchema),
    shouldFocusError: true,
    defaultValues: {
      product: 'sanitaria_per_stranieri',
      source: 'greenia.it',
      policy_effective_date: '',
      privacies: [],
      name: '',
      surname: '',
      email: '',
      fiscal_code: '',
      address: '',
      house_number: '',
      postal_code: '',
      phone: '',
      gender: '',
      date_of_birth: '',
      country_of_birth_code: 'Z000',
      province_of_birth_code: null,
      commune_of_birth_code: null,
      born_abroad: false,
      residence_commune_code: '',
      residence_province_code: '',
      active_customer_id: null,
      existing_customer: false,
      stranger_name: '',
      stranger_surname: '',
      europe_world: '',
      days_of_staying: '',
    },
  });

  const formDataFlow = watch();

  const updateFormData = (data) => {
    const keys = Object.keys(data);
    keys.forEach((key) => {
      setValue(key, data[key], {
        shouldValidate: true,
        shouldDirty: true,
        shouldTouch: true,
      });
    });
    trigger(keys);
  };

  const isValid = (name) => {
    if (errors[name]?.message !== undefined) {
      return false;
    }

    if (touchedFields[name] === undefined) {
      return false;
    }

    return true;
  };

  const privacyItemChangeHandler = (value, item) => {
    if (!value.target.checked) {
      const newArray = [...formDataFlow.privacies];
      const index = newArray.indexOf(item.id);
      newArray.splice(index, 1);
      updateFormData({
        privacies: newArray,
      });
      return;
    }
    const answersNewArray = [...formDataFlow.privacies];
    answersNewArray.push(item.id);
    updateFormData({
      privacies: answersNewArray,
    });
  };

  const getCustomerData = async (customerId) => {
    sanitariaStranieriGetCustomerData(customerId);
  };

  useEffect(() => {
    saniariaStranieriGetInitialData();

    return () => {
      resetSanitariaStranieriForm();
    };
  }, []);

  useEffect(() => {
    if (
      location.state !== null
          && location.state.activeCustomerId !== undefined
    ) {
      getCustomerData(location.state.activeCustomerId);
    }
  }, []);

  const getFiscalCode = (birthDate) => {
    const d = birthDate.split('-');
    const fData = {
      name: formDataFlow.name,
      surname: formDataFlow.surname,
      gender: formDataFlow.gender,
      day: d[2],
      month: d[1],
      year: d[0],
    };
    if (formDataFlow.born_abroad) {
      fData.birthplace = states.filter(
        (s) => s.state_code === formDataFlow.country_of_birth_code,
      )[0].name;
      fData.birthplaceProvincia = 'EE';
    } else {
      fData.birthplace = municipalities.filter(
        (municipality) => municipality.cadastral_code === formDataFlow.commune_of_birth_code,
      )[0].name;
      fData.birthplaceProvincia = formDataFlow.province_of_birth_code;
    }
    return generateFiscalCode(fData);
  };

  const validateDataPrivacy = () => {
    let { error, message } = '';

    dataPrivacy.forEach((privacy) => {
      if (privacy.required === 1) {
        const userDataArray = { ...formDataFlow }; // make a separate copy of the object
        const index = userDataArray.privacies.indexOf(privacy.id);

        if (index < 0) {
          error = true;
          message = 'Tutti i campi sono obbligatori! Tutta la privacy con la stella deve essere controllata';
        }
      }
    });
    return { error, message };
  };

  const submitForm = async (formDataFlow) => {
    const { error, message } = validateDataPrivacy();

    if (error) {
      generalAlertError(message);
      return;
    }

    const _userData = { ...formDataFlow };

    if (_userData.born_abroad && _userData.country_of_birth_code === 'Z000') {
      window.scrollTo(0, 0);
      generalAlertErrorToast('Non puoi selezionare Italia come stato estero!');
      return;
    }

    if (
      _userData.born_abroad
        && (_userData.country_of_birth_code === null
          || _userData.country_of_birth_code === ''
          || _userData.country_of_birth_code == undefined)
    ) {
      window.scrollTo(0, 0);
      generalAlertErrorToast('Devi selezionare uno stato estero!');
      return;
    }

    if (
      formDataFlow.date_of_birth !== ''
        && formDataFlow.date_of_birth !== null
    ) {
      _userData.date_of_birth = moment(formDataFlow.date_of_birth).format(
        'YYYY-MM-DD',
      );
    } else {
      _userData.date_of_birth = null;
    }
    _userData.policy_effective_date = moment(
      formDataFlow.policy_effective_date,
    ).format('YYYY-MM-DD');
    _userData.fiscal_code = formDataFlow.gender !== 'G'
      ? getFiscalCode(formDataFlow.date_of_birth)
      : null;
    delete _userData.existing_customer;

    sanitariaStranieriSubmitAnswers(_userData, navigate);
  };

  useEffect(() => {
    if (skipCheckGenderRef.current === true) {
      skipCheckGenderRef.current = false;
      return;
    }

    const triggerValidation = {
      shouldTouch: true,
      shouldDirty: true,
      shouldValidate: true,
    };

    if (
      ['M', 'F'].includes(formDataFlow.gender)
        && formDataFlow.date_of_birth === null
    ) {
      setValue('date_of_birth', '', triggerValidation);
      setValue('country_of_birth_code', 'Z000', triggerValidation);
      setValue('province_of_birth_code', null, triggerValidation);
      setValue('commune_of_birth_code', null, triggerValidation);
      setValue('born_abroad', false, triggerValidation);
      setValue('name', '', triggerValidation);
      setValue('surname', '', triggerValidation);
    }
  }, [formDataFlow.gender, formDataFlow.date_of_birth]);

  useEffect(() => {
    const values = getValues();

    if (values !== undefined) {
      const objectKeys = Object.keys(values);

      objectKeys.forEach((key) => {
        if (values[key] !== '' && values[key] !== null) {
          setValue(key, values[key], {
            shouldTouch: true,
            shouldDirty: true,
            shouldValidate: true,
          });
        }
      });
    }
  }, []);

  useEffect(() => {
    const callAsync = async () => {
      if (
        location.state !== null
            && location.state.requestToken !== undefined
      ) {
        skipCheckGenderRef.current = true;
        const {
          data: {
            data: { userData },
          },
        } = await modifyRequestData(location.state.requestToken);
        updateFormData(userData);
      }
    };

    callAsync();
  }, [location]);

  if (loading) {
    return <QuoteGenerateLoading />;
  }
  return (
    <SanitariaFormFlowStyles>
      <SanitariaFormStyles onSubmit={handleSubmit(submitForm)}>

        <CenteredDivTitle className="d-flex align-items-center justify-content-center gap-3">
          <CenteredPageTitle title="Completa i dati" />
        </CenteredDivTitle>

        <div className="form-container">
          <FormInputText
            placeholder="Nome"
            label="Nome Stranieri"
            registration={register('stranger_name')}
            error={errors.stranger_name?.message}
            valid={isValid('stranger_name')}
          />
          <FormInputText
            placeholder="Cognome"
            label="Cognome Stranieri"
            registration={register('stranger_surname')}
            error={errors.stranger_surname?.message}
            valid={isValid('stranger_surname')}
          />

        </div>

        <FormContainer>
          <div className="title fw-bold">Dati Contraente</div>
          <div className="form-inputs">
            <div className="row">
              <FormInputRadio
                label="Genere"
                options={genders.filter((gender) => gender.value !== 'G')}
                name="gender"
                registration={register('gender')}
                error={errors.gender?.message}
                valid={isValid('gender')}
              />
            </div>

            {formData.gender !== 'G' && (
            <>
              <FormInputText
                label="Nome"
                registration={register('name')}
                error={errors.name?.message}
                valid={isValid('name')}
              />

              <FormInputText
                registration={register('surname')}
                error={errors.surname?.message}
                valid={isValid('surname')}
                label="Cognome"
              />

              <FormInputDate
                label="Data di nascita"
                registration={register('date_of_birth')}
                minDate={getMinAllowedBirthday()}
                maxDate="2021-01-01"
                error={errors.date_of_birth?.message}
                valid={isValid('date_of_birth')}
              />
              <FormReactSelectInputBirthPlace
                states={states}
                options={municipalities}
                label="Luogo di nascita"
                name="commune_of_birth_code"
                values={{
                  commune_of_birth_code: formDataFlow.commune_of_birth_code,
                  province_of_birth_code:
                        formDataFlow.province_of_birth_code,
                  born_abroad: formDataFlow.born_abroad,
                  country_of_birth_code: formDataFlow.country_of_birth_code,
                }}
                onChange={(value) => updateFormData({
                  commune_of_birth_code: value.commune_of_birth_code,
                  province_of_birth_code: value.province_of_birth_code,
                  born_abroad: value.born_abroad,
                  country_of_birth_code: value.country_of_birth_code,
                })}
                valid={isValid('commune_of_birth_code')}
                error={errors.commune_of_birth_code?.message}
                valid_country_of_birth_code={isValid(
                  'country_of_birth_code',
                )}
                error_country_of_birth_code={
                      errors.country_of_birth_code?.message
                    }
              />

              {showManualAdress ? (
                <FormInputAddressCompleteManual
                  municipalities={municipalities}
                  formData={formDataFlow}
                  isValid={isValid}
                  errors={errors}
                  register={register}
                  updateFormData={updateFormData}
                />
              ) : (
                <FormInputAddressComplete
                  municipalities={municipalities}
                  formData={formDataFlow}
                  isValid={isValid}
                  errors={errors}
                  register={register}
                  updateFormData={updateFormData}
                  addressId="autocomplete-address-contractor"
                  setShow={setShowManualAdress}
                />
              )}
            </>
            )}
            <FormInputText
              registration={register('email')}
              label="E-mail"
              error={errors.email?.message}
              valid={isValid('email')}
            />
            <FormInputText
              registration={register('phone')}
              label="Phone"
              paragraph="Numeri di rete fissa non accettati dalle aziende"
              error={errors.phone?.message}
              valid={isValid('phone')}
              classNameInput="mb-4"
            />

          </div>
        </FormContainer>
        <div className="form-container">

          <FormInputSelect
            placeholder="Seleziona"
            label="Provenienza"
            registration={register('europe_world')}
            error={errors.europe_world?.message}
            valid={isValid('europe_world')}
            options={europe_or_world}
          />
          <FormInputNumber
            registration={register('days_of_staying')}
            error={errors.days_of_staying?.message}
            valid={isValid('days_of_staying')}
            label="Durata in Giorno"

          />
          <FormInputDate
            label="Data Decorrenza"
            registration={register('policy_effective_date')}
            minDate={moment().format('YYYY-MM-DD')}
            error={errors.policy_effective_date?.message}
            valid={isValid('policy_effective_date')}
          />
        </div>

        <div>
          <h5 className="text-center">Informativa Privacy e IVASS</h5>

          <div className="text-center text-decoration-underline mt-2">
            <a
              onClick={() => greeniaPrivacyPolicy()}
              style={{ cursor: 'pointer' }}
            >
              <small className="text-center">Greenia Privacy Policy</small>
            </a>
          </div>
          {dataPrivacy.map((privacy, i) => (
            <InputPrivacy
              required={privacy.required}
              label={privacy.content}
              name={privacy.type}
              id={privacy.type}
              key={i}
              checked={formDataFlow?.privacies?.includes(privacy.id)}
              onChange={(value) => privacyItemChangeHandler(value, privacy)}
            />
          ))}
        </div>
        <div className="footer-buttons button-profession-step">
          <div className="d-flex justify-content-between">
            <button type="submit" className="btn btn-questionnaire">
              Vai a preventivo
            </button>
          </div>
        </div>
      </SanitariaFormStyles>
    </SanitariaFormFlowStyles>
  );
}

export default SanitariaPerStranieriFormFlow;
