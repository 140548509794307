import React, { useEffect } from 'react';
import { useSelector } from 'react-redux';
import { useNavigate, useParams } from 'react-router-dom';
import { RiskContainer, SanitariaStranieriQuotesStyles } from './styles/SanitariaPerStranieriResultPage.styles';
import SanitariaStranieriInfoCard from '../../common/components/redesignComponents/Cards/SanitariaStranieriInfoCard';
import { GuaranteesDefaultStateStyles } from '../../common/components/redesignComponents/common/GuaranteesDefaultState.styles';
import { quotationTypes } from '../../common/constants';
import QuoteGenerateLoading from '../../common/components/elements/QuoteGenerateLoading';
import ErrorComponent from '../../common/components/ErrorComponent';
import { confirmAlert, generalAlertError } from '../../common/utils/alerts';
import {
  addSanitariaStranieriQuoteToCart, removeSanitariaStranieriQuoteFromCart, sanitariaStranieriQuotesGetQuotes,
} from '../../features/insuranceRequests/insuranceRequestsActions';
import { EstimateContainer } from '../../common/components/redesignComponents/common/Redesign.styles';
import { FailedQuotesContainer, FailedQuoteTitle } from '../RcQuotes.styles';
import { BACKEND_BASE_URL } from '../../config';
import SanitariaStranieriModalButtons from './components/SanitariaStranieriModalButtons';
import SanitariaStranieriModal from './components/SanitariaStranieriModal';

function SanitariaPerStranieriResultPage() {
  const { requestToken } = useParams();
  const navigate = useNavigate();
  const {
    loading,
    error,
    insuranceRequest,
    customer,
    quotations,
    addingQuote,
    processingQuote,
    failedQuotes,
    riskQuotations,
  } = useSelector((store) => store.insuranceRequests.sanitaria_stranieri_quotes);

  const refresh = () => sanitariaStranieriQuotesGetQuotes(requestToken);

  const modifyData = () => {
    navigate('/quote/sanitaria-stranieri', {
      state: { requestToken },
    });
  };

  const selectQuotation = (quoteId, closeButton) => {
    confirmAlert('Sei Sicuro di aggiungere questa quotazione?', () => addSanitariaStranieriQuoteToCart(quoteId, refresh, modifyData, closeButton));
  };

  const removeItemFromCart = (quoteId) => {
    confirmAlert('Sei Sicuro di rimuovere questa quotazione?', () => removeSanitariaStranieriQuoteFromCart(quoteId, refresh, () => {}));
  };

  useEffect(() => {
    sanitariaStranieriQuotesGetQuotes(requestToken);
  }, [requestToken]);

  const handleRequestPurchase = () => {
    if (processingQuote === null) {
      generalAlertError('Seleziona Prima La Responsabilita Civile', 'Errore');
      return;
    }

    navigate(`/quotes/sanitaria-stranieri/purchase/${requestToken}`);
  };

  const getTotal = () => {
    let quoteInProccessAmount = 0;
    let risksTotalAmount = 0;

    if (processingQuote !== null && processingQuote.amount !== null) {
      quoteInProccessAmount = parseFloat(processingQuote.amount);
    }

    const riskTypes = quotationTypes.filter((t) => t.id !== 'res_civile');

    for (let x = 0; x < riskTypes.length; x++) {
      const type = riskTypes[x];
      if (riskQuotations && riskQuotations[type.id] !== undefined) {
        for (let i = 0; i < riskQuotations[type.id].length; i++) {
          const item = riskQuotations[type.id][i];

          risksTotalAmount += parseFloat(item.amount);
        }
      }
    }

    return (quoteInProccessAmount + risksTotalAmount).toFixed(2);
  };

  if (loading) {
    return <QuoteGenerateLoading countNr={80} />;
  }

  if (error !== null) {
    return <ErrorComponent error={error} />;
  }

  return (
    <SanitariaStranieriQuotesStyles>
      <div className="resultsPage">
        <div className="row">
          <div className="col-12 col-xxl-4 mb-2 mb-xxl-0 result-home-first-step">
            <SanitariaStranieriInfoCard
              customer={customer}
              insuranceRequest={insuranceRequest}
              modifyData={modifyData}
            />
          </div>
          <div className="col-12 col-xxl-8 result-home-second-step">
            <GuaranteesDefaultStateStyles>
              {processingQuote === null ? (
                <h5 className="fw-semibold pt-2 ps-lg-2">
                  Seleziona Le Quotazioni Che Vuoi Aquistare
                </h5>
              ) : (
                <div className="container mt-4">
                  <ul className="list-unstyled">
                    {processingQuote !== null && (
                    <li>
                      <div className="d-flex justify-content-between align-items-center">
                        <h5>
                          {processingQuote.company.name}
                          {' '}
                          <span className="text-success">
                            (
                            {processingQuote.product_name}
                            )
                          </span>
                        </h5>
                        <h5 className="fw-bold">
                          {parseFloat(
                            processingQuote.amount
                              ? processingQuote.amount
                              : 0,
                          ).toFixed(2)}
                          {' '}
                          €
                        </h5>
                      </div>
                    </li>
                    )}
                    {quotationTypes
                      .filter((t) => t.id !== 'res_civile')
                      .map((type, index) => (
                        <React.Fragment key={index}>
                          {riskQuotations && riskQuotations[type.id]
                            ? riskQuotations[type.id].map((quote, i) => (
                              <li key={i}>
                                <div className="d-flex justify-content-between align-items-center">
                                  <h5>
                                    {quote.company !== null
                                      ? quote.company.name
                                      : quote.preventivas_company
                                        .company_name}
                                    {' '}
                                    <span className="text-success">
                                      (
                                      {
                                            quotationTypes.find(
                                              (item) => item.id === quote.quote_type,
                                            ).name
                                          }
                                      )
                                    </span>
                                  </h5>
                                  {quote.amount !== 0 && (
                                    <h5 className="fw-bold">
                                      {parseFloat(quote.amount).toFixed(2)}
                                      {' '}
                                      €
                                    </h5>
                                  )}
                                </div>
                              </li>
                            ))
                            : ''}
                        </React.Fragment>
                      ))}
                    <hr />
                    <li>
                      <div className="d-flex justify-content-end align-items-center">
                        <h5 className="me-5 fw-bold">Totale:</h5>
                        <h5 className="fw-bold">
                          {' '}
                          {getTotal()}
                          {' '}
                          €
                        </h5>
                      </div>
                    </li>
                  </ul>
                  <div className="d-flex justify-content-end mt-3">
                    <button
                      className="btn btn-success btn-lg rounded-4 px-5 fw-bold"
                      onClick={() => handleRequestPurchase()}
                    >
                      Procedi
                    </button>
                  </div>
                </div>
              )}
            </GuaranteesDefaultStateStyles>
          </div>
        </div>
        <div className="row">
          <div className="col-12 ">
            <EstimateContainer>
              <div className="d-flex gap-2 justify-content-end ">
                <div>
                  {/* {localStorage.getItem('quoteNewResult') === 'done' && (
                      <div style={{ cursor: 'pointer' }} onClick={() => firePurchaseTour()}>
                        <button className="btn btn-success px-2 py-1">Tutorial</button>
                      </div>
                    )} */}
                </div>
              </div>
            </EstimateContainer>
          </div>
        </div>
        <RiskContainer className="mb-3 pb-3 result-home-third-step">
          {quotationTypes.map((type, index) => (
            <React.Fragment key={index}>
              {quotations && quotations[type.id] && (
              <SanitariaStranieriModalButtons
                insuranceRequest={insuranceRequest}
                processingQuote={processingQuote}
                type={type}
                removeItemFromCart={removeItemFromCart}
                riskQuotations={riskQuotations}
              />
              )}
            </React.Fragment>
          ))}
        </RiskContainer>
      </div>
      {quotationTypes.map((type, index) => (
        <SanitariaStranieriModal
          insuranceRequest={insuranceRequest}
          type={type}
          key={index}
          addingQuote={addingQuote}
          quotations={quotations}
          selectQuotation={selectQuotation}
        />
      ))}
      {failedQuotes !== null && failedQuotes.length > 0 && (
      <div className="row mt-5">
        <FailedQuoteTitle>
          Queste compagnie non hanno risposto con una quotazione.
        </FailedQuoteTitle>
        {failedQuotes.map((quote, index) => (
          <div className="col-3" key={index}>
            <FailedQuotesContainer>
              <img
                src={BACKEND_BASE_URL + quote.company.logo}
                alt={`${quote.company.name} Logo`}
              />
            </FailedQuotesContainer>
          </div>
        ))}
      </div>
      )}
    </SanitariaStranieriQuotesStyles>
  );
}

export default SanitariaPerStranieriResultPage;
